import React, { useState } from 'react'
import styles from '../SignUp/SignUp.module.css'
import loginStyle from './Login.module.css'
import { LuEye, LuEyeOff } from 'react-icons/lu';
import CallApi from '../Api/Callapi';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
export default function Login({ isSignedUp, passwordForgot }) {

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({ email: '', password: '' });
    const [remeberberMe, setRememberMe] = useState(false);

    const baseApiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD;
    const dashboardBaseUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DASHBOARD_URL_DEV : process.env.REACT_APP_DASHBOARD_URL_PROD;
    const handleLogin = async () => {
        try {
            const response = await CallApi({
                url: `${baseApiUrl}/api/v1/auth/login`,
                method: 'POST',
                data: {
                    mobileNumber: email,
                    password: password,
                    rememberMe: remeberberMe
                }
            })
            if (response.error) {
                toast.error(response.data.message);
            } else {
                const accessToken = response.data.data.accessToken;
                // const resp = await CallApi({
                //     url: `${baseApiUrl}/api/v1/auth/generate-login-token`,
                //     method: 'POST',
                //     headers: {
                //         Authorization: `Bearer ${accessToken}`,
                //     },
                //     data: {
                //         withCredentials: true,
                //     }
                // })
                // if(resp?.error){
                //     toast.error(resp.data.message);
                
                // }
                // toast.success(response.data.message);
                // window.location.href = `${dashboardBaseUrl}`;

                console.log(accessToken);
                await axios.post(
                    `${baseApiUrl}/api/v1/auth/generate-login-token`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                        withCredentials: true,
                    }
                );

                window.location.href = `${dashboardBaseUrl}`;


            }

        } catch (error) {
            console.log(error);
            toast.error('Something went wrong. Please try again later.')
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = { email: '', password: '' };
        if (!email) {
            newErrors.email = 'Please enter your email address or mobile number.';
        } else if (!/^\d{10}$/.test(email) && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            newErrors.email = 'Please enter a valid 10-digit number or email address.';
        }

        if (!password) {
            newErrors.password = 'Please enter your password.';
        } else if (password.length < 6) {
            newErrors.password = 'The password is incorrect. Please try again.';
        }

        if (!newErrors.email && !newErrors.password) {
            setErrors({ email: '', password: '' });
            handleLogin()
            //  window.location.href = '/';
        } else {
            setErrors(newErrors);
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (errors.email) {
            setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
        }
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (errors.password) {
            setErrors((prevErrors) => ({ ...prevErrors, password: '' }));
        }
    };

    const isSignUp = () => {
        isSignedUp(true);
    }

    const togglePassVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const handleForgotPassword = () => {
        passwordForgot(true);
    }


    return (
        <div>
            {/* <div className={styles.formSection}> */}
            <ToastContainer />
            <form onSubmit={handleSubmit} className={styles.signupForm}>
                <img
                    src="/Assets/Modules/SignUp/Logo.svg"
                    alt="Logo..."
                    style={{
                        width: "25px",
                        height: "25px",
                        marginBottom: "8px",
                    }}
                />
                <h1>Login</h1>
                <h5 style={{
                    marginBottom: "1rem",
                }}>Welcome back! Please enter your details.</h5>

                {/* Email Address/Mobile Number */}
                <label>Email Address/Mobile No.<span class={styles.required}>*</span></label>
                <input
                    type="text"
                    placeholder="Enter your email address"
                    className={styles.input}
                    value={email}
                    onChange={handleEmailChange}

                />
                <div className={loginStyle.errorContainer}>
                    {errors.email && <p className={loginStyle.errorText}>{errors.email}</p>}
                </div>

                {/* Password */}
                <label>Password<span class={styles.required}>*</span></label>
                <section className={loginStyle.passwordStyle}>
                    <input
                        type={isPasswordVisible ? "text" : "password"}
                        placeholder="Create a password"
                        className={styles.input}
                        value={password}
                        onChange={handlePasswordChange}
                    />
                    {isPasswordVisible ? (
                        <LuEye
                            onClick={togglePassVisibility}
                            className={loginStyle.eyeStyle}
                        />
                    ) : (
                        <LuEyeOff
                            onClick={togglePassVisibility}
                            className={loginStyle.eyeStyle}
                        />
                    )}
                </section>
                <div className={loginStyle.errorContainer}>
                    {errors.password && <p className={loginStyle.errorText}>{errors.password}</p>}
                </div>

                <div className={loginStyle.terms}>
                    <input onClick={() => setRememberMe(!remeberberMe)} type="checkbox" className={loginStyle.check} />
                    <label className={loginStyle.rememberLabel}>
                        Remeber me
                    </label>
                    <p className={loginStyle.footerText}>
                        <span className={loginStyle.forgotStyle} onClick={handleForgotPassword}>Forgot Password?</span>
                    </p>
                </div>
                <button type="submit" className={styles.button}>
                    Login
                </button>

                <p className={styles.footerText}>
                    Don’t have and account? <span className={loginStyle.forgotStyle} onClick={isSignUp} >Sign up</span>
                </p>
            </form>
            {/* </div> */}
        </div>
    )
}
