import { Link } from 'react-router-dom';
import styles from './OtpVerfication.module.css';
import OtpInput from '../../Authentication/Signup/Otp/OtpInput';
import { useCallback, useEffect, useState } from 'react';

const OtpVerification = ({ isVerifyed, mobile, email, generateOTP, initialOtpGenerated }) => {

    const [state, setState] = useState({
        errors: {
            otpFilled: false,
            otpVerified: false,
        },
    });
    const [timer, setTimer] = useState(60);
    const [isTimerActive, setIsTimerActive] = useState(false);

    useEffect(() => {
        if (initialOtpGenerated) {
            setIsTimerActive(true);
        }
    }, [initialOtpGenerated]);

    useEffect(() => {
        let interval = null;
        if (isTimerActive && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            setIsTimerActive(false);
        }
        return () => clearInterval(interval);
    }, [isTimerActive, timer]);

    const handleOtpFilled = useCallback((isFill) => {
        setState((prevState) => ({
            ...prevState,
            errors: {
                ...prevState.errors,
                otpFilled: isFill,
            },
        }));
    }, []);

    const handleOtpVerified = useCallback((isVerify) => {
        setState((prevState) => ({
            ...prevState,
            errors: {
                ...prevState.errors,
                otpVerified: isVerify,
            },
        }));
    }, []);

    const profileCompleted = (params) => {
        isVerifyed(true);
    }

    const handleResendCode = async (e) => {
        e.preventDefault();
        await generateOTP();
        setTimer(60);
        setIsTimerActive(true);
    };

    return (
        <div>
            {!state.errors.otpVerified && <div className={styles.cont}>
                <img
                    src="/Assets/Modules/SignUp/Otp.svg"
                    className={styles.topLogo}
                    alt='logo'
                />
                <section className={styles.topHeading}>
                    <text className={styles.topHeading__first}>
                        OTP Verification
                    </text>
                    <text className={styles.topHeading__second}>
                        We have sent a 4-digit verification code to your Email Address {email ? email : 'example@gmail.com '}
                        and Mobile number “+91-9*******90. Please enter the code below.
                    </text>
                </section>
                <section className={styles.info}>
                    <OtpInput
                        onOtpComplete={handleOtpFilled}
                        onOtpVerified={handleOtpVerified}
                        mobileNumber={mobile}
                    />
                    <div className={styles.errorAndTimer}>
                        {!state.errors.otpFilled && (
                            <div className={styles.error}>Please fill all OTP fields</div>
                        )}
                        {!state.errors.otpVerified && state.errors.otpFilled && (
                            <div className={styles.error}>Invalid OTP</div>
                        )}
                        {isTimerActive && (
                            <div className={styles.timer}>Resend OTP in {timer}s</div>
                        )}
                    </div>
                </section>
                <Link className={styles.bottomButton} onClick={() => {
                    setState((prevState) => ({
                        ...prevState, errors: {
                            ...prevState.errors,
                            otpVerified: true,
                        },
                    }));
                }}>
                    Verify
                </Link>
                <label className={styles.labelText}>
                    Didn't receive code? <span className={styles.resendStyle} onClick={handleResendCode}>Resend Code</span>{" "}.
                </label>
            </div>}
            {state.errors.otpVerified &&
                <div className={styles.cont}>
                    <img
                        src="/Assets/Modules/SignUp/Verified.svg"
                        className={styles.topLogo}
                        alt='logo'
                    />
                    <section className={styles.topHeading}>
                        <text className={styles.topHeading__first}>
                            Verification successful!
                        </text>
                        <text className={styles.topHeading__second}>
                            Your Email Address and mobile number has been verified successfully. Please complete your profile setup
                        </text>
                    </section>
                    <Link className={styles.bottomButton} onClick={profileCompleted}>
                        Complete Profile
                    </Link>
                </div>}
        </div>
    );
}

export default OtpVerification;