import React from 'react';
import styles from './Terms.module.css';
import Header from '../Home/Header';
import { Footer } from '../Home/Footer';
import TermsAndConditions from '../../Authentication/Signup/Documents/TermAndConditions/TermAndConditions';

// const termsData = [
//     {
//         title: "1. Acceptance of Terms",
//         content: "By using this website or any services provided by Connect Solutions, you agree to these Terms and Conditions and our Privacy Policy. If you do not agree, please refrain from using our services."
//     },
//     {
//         title: "2. Services Provided",
//         content: "Connect Solutions acts as a platform offering services in multiple sectors, including but not limited to Healthcare, Entertainment, Event Planning, Pet Care, Home Services, Construction. The availability of services may vary based on your location."
//     },
//     {
//         title: "3. User Responsibilities",
//         content: "Users must provide accurate and complete information during service requests. Users are responsible for maintaining the confidentiality of their account credentials. Misuse of services or the platform is prohibited."
//     },
//     {
//         title: "4. Service Providers",
//         content: "We work with third-party service providers to deliver certain services. Connect Solutions is not liable for any direct agreements or disputes between users and service providers."
//     },
//     {
//         title: "5. Payments and Refunds",
//         content: "Payments for services must be made as per the terms specified at the time of booking. Refunds, if applicable, will be governed by the specific service policies."
//     },
//     {
//         title: "6. Intellectual Property",
//         content: "All content on the website, including text, graphics, logos, and software, is the property of Connect Solutions. Unauthorized use or reproduction is prohibited."
//     },
//     {
//         title: "7. Limitation of Liability",
//         content: "Connect Solutions is not liable for any direct, indirect, or incidental damages resulting from the use of our website or services."
//     },
//     {
//         title: "8. Modifications to Terms",
//         content: "Connect Solutions reserves the right to modify these Terms and Conditions at any time. Changes will be effective upon posting on the website."
//     },
//     {
//         title: "9. Governing Law",
//         content: "These Terms and Conditions are governed by and construed in accordance with the laws of [Your Country/State]. For inquiries or concerns, please contact us at [Contact Email]."
//     }
// ];

export const Terms = () => {
    return (
        <>
            <Header />
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.headerContent}>
                        <div className={styles.headerWrapper}>
                            <div className={styles.sectionLabel}>LEGAL</div>
                            <div className={styles.mainTitle}>Terms & Conditions</div>
                            <div className={styles.subtitle}>
                                By using our services, you agree to our terms and conditions,
                                ensuring a seamless and transparent experience.
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className={styles.contentWrapper}>
                    <div className={styles.termsContent}>
                        <div className={styles.effectiveDate}>
                            <span className={styles.textRegular}>Effective Date: </span>
                            <span className={styles.textBold}>[Insert Date]</span>
                        </div>

                        <div className={styles.welcome}>
                            <span className={styles.textRegular}>
                                Welcome to Connect! These Terms and Conditions ("Terms") govern your
                                use of the Connect platform as a service provider across various
                                sectors. By listing yourself on Connect and providing services to
                                customers, you agree to comply with these Terms.
                            </span>
                        </div>

                        {termsData.map((section, index) => (
                            <div key={index}>
                                <div className={`${styles.textBold} ${styles.sectionTitle}`}>
                                    {section.title}
                                </div>
                                <div className={`${styles.textLight} ${styles.sectionContent}`}>
                                    {section.content}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className={styles.scrollBarContainer}>
                        <div className={styles.scrollBarWrapper}>
                            <div className={styles.scrollBarTrack} />
                        </div>
                    </div>
                </div> */}
                <div className={styles.childContent}>
                <TermsAndConditions hide/>
                </div>
            </div>
            <Footer />
        </>
    );
}