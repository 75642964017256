import React, { useRef } from 'react';
import styles from './BlogSection.module.css';
import { blogdata } from './Blogs/Blogdata';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';

const BlogSection = () => {
    const containerRef = useRef(null);
    const navigate = useNavigate();

    const handleMouseDown = (e) => {
        const container = containerRef.current;
        container.isDown = true;
        container.startX = e.pageX - container.offsetLeft;
        container.scrollLeftStart = container.scrollLeft;
    };

    const handleMouseLeave = () => {
        const container = containerRef.current;
        container.isDown = false;
    };

    const handleMouseUp = () => {
        const container = containerRef.current;
        container.isDown = false;
    };

    const handleMouseMove = (e) => {
        const container = containerRef.current;
        if (!container.isDown) return;
        e.preventDefault();
        const x = e.pageX - container.offsetLeft;
        const walk = (x - container.startX); // Scroll-fast
        container.scrollLeft = container.scrollLeftStart - walk;
    };

    const handleBlogClick = (blog) => {
        const encodedData = encodeURIComponent(JSON.stringify(blog));
        navigate(`/blogdetails?data=${encodedData}`);
    };

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "ItemList",
        "itemListElement": blogdata.map((blog, index) => ({
            "@type": "ListItem",
            "position": index + 1,
            "item": {
                "@type": "BlogPosting",
                "headline": blog.title,
                "author": {
                    "@type": "Person",
                    "name": blog.name
                },
                "datePublished": blog.date,
                "image": blog.image,
                "articleSection": blog.category
            }
        }))
    };

    return (
        <section id='blogs' className={styles.blogSection}>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <header className={styles.header}>
                <h3>BLOG/ARTICLES</h3>
                <h2>Helpful Insights & Stories</h2>
                <p>Read our stories and helpful blogs!</p>
                <button onClick={() => navigate('/blogs')} className={styles.seeAll}>See all blogs</button>
            </header>
            <nav
                className={styles.blogContainer}
                ref={containerRef}
                onMouseDown={handleMouseDown}
                onMouseLeave={handleMouseLeave}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                aria-label="Blog posts"
            >
                {blogdata.map((blog, index) => (
                    <article
                        onClick={() => handleBlogClick(blog)}
                        className={styles.blogCard} key={index}>
                        <LazyLoadImage
                            src={blog.image}
                            alt={`Featured image for ${blog.title}`}
                            className={styles.blogImage}
                            draggable="false"
                        />
                        <div className={styles.blogInfo}>
                            <header className={styles.blogHeader}>
                                <LazyLoadImage src={blog.profilePic} alt={`Profile picture of ${blog.name}`} className={styles.profilePic} />
                                <span className={styles.name}>{blog.name}</span>
                                {/* <span className={styles.separator}>-</span> */}
                                <span className={styles.date}>{blog.date}</span>
                            </header>
                            <h3 className={styles.blogTitle}>{blog.title}</h3>
                            <div className={styles.categoryTag} style={{ color: getCategoryColor(blog.category) }}>
                                {blog.category}
                            </div>
                        </div>
                    </article>
                ))}
            </nav>
        </section>
    );
};

export const getCategoryColor = (category) => {
    switch (category) {
        case 'Construction':
            return '#ff5722';
        case 'Events':
            return '#4caf50';
        case 'Petcare':
        case 'Pet Care':
            return '#2196f3';
        case 'Home Services':
            return '#ff9800';
        case 'Healthcare':
            return '#00017d';
        default:
            return '#757575';
    }
};

export default BlogSection;