const CallApi = async ({ url, method, data = null, headers = {} }) => {
    try {
        const options = {
            method: method.toUpperCase(),
            headers: {
                'Content-Type': 'application/json',
                ...headers
            }
        };

        if (method.toUpperCase() === 'GET' && data) {
            const params = new URLSearchParams(data);
            url += `?${params.toString()}`;
        } else if (method.toUpperCase() === 'POST' && data) {
            options.body = JSON.stringify(data);
        }

        const response = await fetch(url, options);
        const result = await response.json();
        if (!response.ok) {
            // Handle error based on response status
            // if (response.status === 400 || response.status === 429) {
                console.log('API call error:', result);
                return {
                    status: response.status,
                    data: result,
                    error: !response.ok
                };
            // }
        } else {
            return {
                status: response.status,
                data: result,
                error: !response.ok
            };
        }
    } catch (error) {
        console.error('API call error:', error);
        return error;
    }
};

export default CallApi;