import React, { useEffect, useState } from 'react';
import styles from './SelectionForm.module.css';
import SelectionGroup from '../utils/SelectionGroup';
import CallApi from '../Api/Callapi';

const dummy_sectors = ['Construction', 'Home Services', 'Entertainment', 'Events', 'Healthcare', 'Petcare'];
const dummy_roles = Array(6).fill({ name: 'Loreum ipsum' });
const services = Array(21).fill({ name: 'Loreum ipsum' });

export default function SelectionForm({ onValidation, showErrors,   onSelectionchange }) {
    const [selectedSector, setSelectedSector] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [selectedServices, setSelectedServices] = useState([]);
    const [errors, setErrors] = useState({});
    const [sectors, setSectors] = useState(dummy_sectors);
    const [roles, setRoles] = useState([]);
    
    const baseApiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD;

    const getSectors = async () => {
        try {
            const response = await CallApi({
                url: `${baseApiUrl}/api/v1/sectors`,
                method: 'POST',
                data: {
                    withCredentials: true,
                }
            })
            console.log(response.data.data.sectors)
            setSectors(response.data.data.sectors)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getSectors();
    }, []);

    useEffect(() => {
        validateForm();
        onSelectionchange({ sector: selectedSector, role: selectedRole,})
    }, [selectedSector, selectedRole, selectedServices, showErrors, roles]);

    useEffect(() => {
        if (selectedSector) {
            getRoles();
            setSelectedRole(null);
        } else {
            setRoles([]);
            setSelectedRole(null);
        }
    }, [selectedSector]);

    const getRoles = async () => {
        try {
            const response = await CallApi({
                url: `${baseApiUrl}/api/v1/categories/sector-wise`,
                method: 'POST',
                data: {
                    sectorId: selectedSector?.id,
                }
            })
            console.log(response.data.data.categories)
            setRoles(response?.data?.data?.categories)
        } catch (error) {
            console.log(error)
        }
    }

    const validateForm = () => {
        console.log(!selectedRole && roles.length !== 0, !selectedRole || roles.length !== 0, !selectedRole, roles.length !== 0)
        let newErrors = {};
        let isValid = true;

        if (!selectedSector) {
            newErrors.sector = "Please select a sector";
            isValid = false;
        }
        if (roles.length > 0 && !selectedRole) {
            newErrors.role = "Please select a role";
            isValid = false;
        }
        // if (selectedServices.length === 0) {
        //     newErrors.services = "Please select at least one service";
        //     isValid = false;
        // }

        setErrors(newErrors);
        onValidation(isValid);
    };

    return (
        <div className={styles.container}>
            <main>
                <SelectionGroup
                    title="Select Sector"
                    options={sectors}
                    selectedOption={selectedSector}
                    onSelect={setSelectedSector}
                    ariaLabel="Select your business sector"
                    valueKey="id"
                    labelKey="sectorName"
                />
                {showErrors && errors.sector && <p className={styles.errorMessage}>{errors.sector}</p>}
                {roles.length > 0 &&
                    <SelectionGroup
                        title="Select Role"
                        options={roles}
                        selectedOption={selectedRole}
                        onSelect={setSelectedRole}
                        ariaLabel="Select your role"
                        labelKey={"categoryName"}
                    />}
                {showErrors && errors.role && <p className={styles.errorMessage}>{errors.role}</p>}
                {/* <SelectionGroup
                    title="Select Services"
                    options={services}
                    selectedOptions={selectedServices}
                    onSelect={setSelectedServices}
                    // multiSelect={true}
                    ariaLabel="Select your services"
                    labelKey={"name"}
                />
                {showErrors && errors.services && <p className={styles.errorMessage}>{errors.services}</p>} */}
            </main>
        </div>
    );
}