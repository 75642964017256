import * as React from "react";
import styles from './VideoSection.module.css';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";

const thumbnail = '/Assets/Modules/Home/thumbnail.jpg';

export const VideoSection = () => {
    const [isPlaying, setIsPlaying] = React.useState(false);
    const videoRef = React.useRef(null);

    const handlePlayClick = () => {
        setIsPlaying(true);
        if (videoRef.current) {
            videoRef.current.play();
        }
    };

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "VideoObject",
        "name": "CONNECT App Demonstration",
        "description": "Watch the CONNECT App in action and see how it can transform your way of living.",
        "thumbnailUrl": thumbnail,
        "contentUrl": "/Assets/Modules/Home/Connect_Video.mp4"
    };

    return (
        <section id="video" className={styles.container}>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <div className={styles.contentWrapper}>
                <header className={styles.headerWrapper}>
                    <div className={styles.videoLabel}>VIDEO</div>
                    <h1 className={styles.title}>
                        Watch the CONNECT App in action!
                    </h1>
                </header>
                <main className={styles.videoContainer}>
                    {!isPlaying ? (
                        <>
                            <LazyLoadImage
                                src={thumbnail}
                                className={styles.videoPreview}
                                alt="Video preview of CONNECT App demonstration"
                            />
                            <button
                                className={styles.playButton}
                                onClick={handlePlayClick}
                                aria-label="Play video"
                            >
                                <span className={styles.playIcon} aria-hidden="true" />
                            </button>
                        </>
                    ) : (
                        <video
                            ref={videoRef}
                            className={styles.video}
                            width="560"
                            height="315"
                            controls
                            autoPlay
                        >
                            <source src="/Assets/Modules/Home/Connect_Video.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    )}
                </main>
            </div>
            <footer className={styles.description}>
                Helping you transform the way of living by providing the best services
                at your convenience!
            </footer>
        </section>
    );
};
