import React, { useEffect, useState } from 'react';
import styles from './CustomModal.module.css';

const CustomModal = ({
    show,
    onClose,
    isClosable = true,
    children,
    closeOnOutsideClick = true,
    height = 'auto',
    maxHeight = '90vh',
    mobileHeight = 'auto'
}) => {
    const [isVis, setIsVis] = useState(show);

    useEffect(() => {
        setIsVis(show);
        if (show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [show]);

    if (!isVis) return null;

    const modalStyle = {
        '--modal-height': height,
        '--modal-max-height': maxHeight,
        '--modal-mobile-height': mobileHeight,
        '--modal-mobile-max-height': '90vh',
    };

    const handleOverlayClick = () => {
        if (closeOnOutsideClick && onClose) {
            onClose();
        }
    };

    return (
        <div className={styles.modalOverlay} onClick={handleOverlayClick}>
            <div
                className={`${styles.modalContent} ${styles.responsiveHeight}`}
                onClick={(e) => e.stopPropagation()}
                style={modalStyle}
            >
                {isClosable && !onClose && <span className={styles.modalClose} onClick={() => { setIsVis(false) }}>&times;</span>}
                {isClosable && onClose && <span className={styles.modalClose} onClick={onClose}>&times;</span>}
                <div className={styles.modalInnerContent}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default CustomModal;
