import React, { useCallback, useEffect, useState } from 'react'
import styles from "./SignUp.module.css";

const slides = [
    {
        heading: "Build Your Vision with the Best Hands in the Industry",
        subheading: "Find skilled contractors, laborers, and professionals for every stage of your construction project. Reliable, vetted, and ready to deliver.",
        image: "/Assets/Modules/SignUp/Slider.svg"
    },
    {
        heading: "Bringing Comfort and Care Right to Your Doorstep",
        subheading: "Effortless home services at your fingertips. From cleaning to repairs, our trusted professionals deliver quality and convenience right to your doorstep. ",
        image: "/Assets/Modules/SignUp/Slider2.svg"

    },
    {
        heading: "Your Health, Our Commitment.Trusted care, anytime, anywhere",
        subheading: "Accessible and reliable healthcare services tailored to your needs. Consultations from our trusted professionals to ensure your well-being, anytime and anywhere.",
        image: "/Assets/Modules/SignUp/Slider3.svg"

    },
    {
        heading: "Compassionate Care for Your Furry Friends.Because They Deserve the Best, Always",
        subheading: "Comprehensive pet care services designed to keep your furry friends happy and healthy. From grooming to vet visits, we’re here to provide the care they deserve.",
        image: "/Assets/Modules/SignUp/Slider4.svg"
    },
    {
        heading: "Your Gateway to Unforgettable Moments.Where Fun and Excitement Never End",
        subheading: "Discover a world of fun and excitement with our entertainment services. From live events to on-demand content, we bring unforgettable experiences right to you.",
        image: "/Assets/Modules/SignUp/Slider5.svg"

    },
    {
        heading: "Making Your Events Unforgettable.Seamless Planning, Exceptional Experiences",
        subheading: "Transform your vision into reality with our comprehensive event services. From planning and coordination to execution, we ensure every detail is perfect.",
        image: "/Assets/Modules/SignUp/Slider6.svg"

    }
];

const CarouselScreen = () => {

    const [currentSlide, setCurrentSlide] = useState(0);
    const [isAutoPlaying, setIsAutoPlaying] = useState(true);

    const goToSlide = useCallback((index) => {
        setCurrentSlide(index);
        setIsAutoPlaying(false);
    }, []);

    useEffect(() => {
        if (!isAutoPlaying) return;

        const interval = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % slides.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [isAutoPlaying]);

    const handleMouseEnter = () => setIsAutoPlaying(false);
    const handleMouseLeave = () => setIsAutoPlaying(true);

    return (
        <div className={styles.imageSection}>
            <div className={styles.overlayImage}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                <div
                    className={styles.carouselImage}
                    style={{ transform: `translateX(-${currentSlide * 100}%)`  }}
                    role="region"
                    aria-label="Hero Carousel"
                >
                    {slides.map((slide, index) => (
                        <img
                            key={index}
                            src={slide.image}
                            alt="SignUp..."
                            className={styles.imageStyle}
                            aria-hidden={currentSlide !== index}
                        />
                    ))}
                </div>
            </div>
            <div
                className={styles.overlayText}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className={styles.contentWrapper}>
                    <img
                        loading="lazy"
                        src="/Assets/Modules/SignUp/SliderLogo.svg"
                        className={styles.logo}
                        alt="Company Logo"
                    />
                    <div
                        className={styles.carousel}
                        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
                        role="region"
                        aria-label="Hero Carousel"
                    >
                        {slides.map((slide, index) => (
                            <div
                                key={index}
                                className={styles.slide}
                                aria-hidden={currentSlide !== index}
                            >
                                <div className={styles.textContainer}>
                                    <div className={styles.headingWrapper}>
                                        <h1 className={styles.heading}>
                                            "{slide.heading}"
                                        </h1>
                                        <p className={styles.subheading}>
                                            {slide.subheading}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.dotsWrapper}>
                        <div
                            className={styles.dotsContainer}
                            role="tablist"
                            aria-label="Carousel Navigation"
                        >
                            <div className={styles.dotsPlatter} />
                            {slides.map((_, index) => (
                                <button
                                    key={index}
                                    className={`${styles.dot} ${index === currentSlide ? styles.dotActive : styles.dotInactive
                                        }`}
                                    onClick={() => goToSlide(index)}
                                    role="tab"
                                    aria-label={`Slide ${index + 1} of ${slides.length}`}
                                    aria-selected={index === currentSlide}
                                    tabIndex={index === currentSlide ? 0 : -1}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CarouselScreen
