import React from 'react';
import styles from '../ProfileStep/AddresssDetails.module.css';

export function FormInput({ label, required, placeholder, id, type = "text", error , ...props}) {
    return (
        <div className={styles.inputGroup}>
            <label htmlFor={id} className={styles.inputLabel}>
                {label}
                {required && <span className={styles.required}>*</span>}
            </label>
            <input
                type={type}
                id={id}
                className={styles.inputField}
                placeholder={placeholder}
                required={required}
                aria-label={label}
                {...props}
            />
            {error && <p className={styles.errorText}>{error}</p>}
        </div>
    );
}