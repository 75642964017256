import React from 'react';
import styles from './SelectionOption.module.css';

export const SelectionOption = ({ isSelected, title, description, onSelect }) => {
    return (
        <button
            className={styles.optionButton}
            style={{ borderColor: isSelected ? '#2f4088' : '#f2f2f2' }}
            onClick={onSelect}
            role="radio"
            aria-checked={isSelected}
            tabIndex={0}
        >
            <div className={styles.checkbox}>
                <div className={styles.checkboxWrapper}>
                    <div className={styles.checkboxBase}>
                        {isSelected && <div className={styles.check} />}
                    </div>
                </div>
                <div className={styles.content}>
                    <div className={styles.text}>{title}</div>
                    <div className={styles.supportingText}>{description}</div>
                </div>
            </div>
        </button>
    );
};