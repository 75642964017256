import React, { useState, useEffect } from 'react';
import styles from './Support.module.css';
import ReactIcon from '../utils/ReactIcon';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';

const features = [
    {
        id: 1,
        image: "/Assets/Modules/Home/Support/support.png",
        icon: "https://cdn.builder.io/api/v1/image/assets/fb60b6f73fe84ee99aaecdaf0cb76d4f/ec9d2609545b63483df329231229fee60911a7558b1041db92f433724c489601?apiKey=fb60b6f73fe84ee99aaecdaf0cb76d4f&",
        title: "Diverse Business Sectors & Services",
        description: "Wider reach of all necessary service sectors where consumers and service providers blend in one platform fulfilling their needs.",
        bulletPoints: [
            "Easy access for consumers to find a service of their need",
            "Increased customer reach for service providers",
            "Free & fair market availability for all size entrepreneurs",
            "Regular updates on the addition of new service sectors"
        ]
    },
    {
        id: 2,
        image: "/Assets/Modules/Home/Support/support2.png",
        icon: "https://cdn.builder.io/api/v1/image/assets/fb60b6f73fe84ee99aaecdaf0cb76d4f/ec9d2609545b63483df329231229fee60911a7558b1041db92f433724c489601?apiKey=fb60b6f73fe84ee99aaecdaf0cb76d4f&",
        title: "Effortless Onboarding",
        description: "Simplified, user-friendly onboarding tailored to provide a seamless start for both customers and service providers.",
        bulletPoints: [
            "Intuitive and guided onboarding process.",
            "Quick understanding of platform features.",
            "Designed for both service providers and customers.",
            "Start your journey without delays or complications."
        ]
    },
    {
        id: 3,
        image: "/Assets/Modules/Home/Support/support3.png",
        icon: "https://cdn.builder.io/api/v1/image/assets/fb60b6f73fe84ee99aaecdaf0cb76d4f/ec9d2609545b63483df329231229fee60911a7558b1041db92f433724c489601?apiKey=fb60b6f73fe84ee99aaecdaf0cb76d4f&",
        title: "Personalized Solutions",
        description: "With our hand-picked personalized service schemes, Customers feel valued as your aspirations are our priorities.",
        bulletPoints: [
            "Tailor made service offerings based on choices and usages",
            "Flexible control to design & scale your businesses",
            "24x7 virtual support pre & post service",
            "Dedicated support for business partners"
        ]
    },
    {
        id: 4,
        image: "/Assets/Modules/Home/Support/support4.png",
        icon: "https://cdn.builder.io/api/v1/image/assets/fb60b6f73fe84ee99aaecdaf0cb76d4f/ec9d2609545b63483df329231229fee60911a7558b1041db92f433724c489601?apiKey=fb60b6f73fe84ee99aaecdaf0cb76d4f&",
        title: "Expert Professionals",
        description: "Experience the high-quality service standards with our skilled, expert professionals who are passionate about their work and committed to excellence",
        bulletPoints: [
            "Right person for the right job – our commitment to our customers",
            "Authenticated service professionals with high expertise",
            "Solicited consultant for optimized service need",
            "Ensured quality control throughout the service process"
        ]
    },
    {
        id: 5,
        image: "/Assets/Modules/Home/Support/support5.png",
        icon: "https://cdn.builder.io/api/v1/image/assets/fb60b6f73fe84ee99aaecdaf0cb76d4f/ec9d2609545b63483df329231229fee60911a7558b1041db92f433724c489601?apiKey=fb60b6f73fe84ee99aaecdaf0cb76d4f&",
        title: "Innovative Solutions",
        description: "Keeping our customers to the forefront of convenience and technology, we continuously innovate to meet your evolving needs.",
        bulletPoints: [
            "Predictive trend analysis on service needs and improvement",
            "Concise and insightful invoice, report generation",
            "Innovative features for better customer experience",
        ]
    }
];

export function Support() {
    const [currentFeature, setCurrentFeature] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentFeature((prev) => (prev + 1) % features.length);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const feature = features[currentFeature];

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": feature.title,
        "description": feature.description,
        "provider": {
            "@type": "Organization",
            "name": "CONNECT"
        }
    };

    return (
        <section id="support" className={styles.container}>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <header className={styles.header}>
                <div>
                    <h1 className={styles.title}>
                        Why choose <span className={styles.titleHighlight}>CONNECT</span>?
                    </h1>
                    <p className={styles.subtitle}>
                        Checkout our key value propositions.
                    </p>
                </div>
                <button className={styles.supportButton}>
                    <div className={styles.buttonContent}>
                        <ReactIcon name="MdHeadsetMic" size="34px" color='#fff'/>
                        <span className={styles.supportText}>Support</span>
                    </div>
                </button>
            </header>

            <main className={styles.content}>
                <article className={styles.featureSection}>
                    <LazyLoadImage
                        src={feature.image}
                        className={styles.featureImage}
                        alt={`${feature.title} visualization`}
                    />
                    <div className={styles.featureContent}>
                        <LazyLoadImage
                            src={feature.icon}
                            className={styles.featureIcon}
                            alt={`${feature.title} icon`}
                        />
                        <div className={styles.featureDetails}>
                            <h2 className={styles.featureTitle}>
                                {feature.title}
                            </h2>
                            <p className={styles.featureDescription}>
                                {feature.description}
                            </p>
                            <ul className={styles.featureList}>
                                {feature.bulletPoints.map((point, index) => (
                                    <li className={styles.featureListItem} key={index}>{point}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </article>

                <nav className={styles.pagination}>
                    {features.map((_, index) => (
                        <div
                            key={index}
                            className={index === currentFeature ? styles.paginationDotActive : styles.paginationDot}
                            role="button"
                            tabIndex={0}
                            aria-label={`Go to slide ${index + 1}`}
                            onClick={() => setCurrentFeature(index)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    setCurrentFeature(index);
                                }
                            }}
                        />
                    ))}
                </nav>
            </main>
        </section>
    );
}