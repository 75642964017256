import React, { useState, useCallback } from 'react';
import styles from './NewPassword.module.css';
import { LuEye, LuEyeOff } from 'react-icons/lu';
import loginStyle from '../SignIn/Login.module.css'
import CallApi from '../Api/Callapi';
import { toast, ToastContainer } from 'react-toastify';

export const validationRules = [
    {
        id: 'capital',
        text: "At least one capital Letter (A-Z)",
        info: '/Assets/Modules/ForgotPassword/info.svg',
        validIcon: '/Assets/Modules/ForgotPassword/valid.svg',
        inValidIcon: '/Assets/Modules/ForgotPassword/inValid.svg',
        validate: (password) => /[A-Z]/.test(password)
    },
    {
        id: 'lowercase',
        text: "At least one small Letter (a-z)",
        info: '/Assets/Modules/ForgotPassword/info.svg',
        validIcon: '/Assets/Modules/ForgotPassword/valid.svg',
        inValidIcon: '/Assets/Modules/ForgotPassword/inValid.svg',
        validate: (password) => /[a-z]/.test(password)
    },
    {
        id: 'number',
        text: "At least one number (0-9)",
        info: '/Assets/Modules/ForgotPassword/info.svg',
        validIcon: '/Assets/Modules/ForgotPassword/valid.svg',
        inValidIcon: '/Assets/Modules/ForgotPassword/inValid.svg',
        validate: (password) => /[0-9]/.test(password)
    },
    {
        id: 'length',
        text: "Minimum 8 letters",
        info: '/Assets/Modules/ForgotPassword/info.svg',
        validIcon: '/Assets/Modules/ForgotPassword/valid.svg',
        inValidIcon: '/Assets/Modules/ForgotPassword/inValid.svg',
        validate: (password) => password.length >= 8
    }
];

export const NewPassword = ({ isSignUp, passwordUpdated, mobileNumber, otp }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isFocused, setIsFocused] = useState('');
    const [blurTimeoutId, setBlurTimeoutId] = useState(null);

    const baseApiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD;

    const validatePassword = useCallback((password) => {
        return validationRules.map(rule => ({
            ...rule,
            isValid: rule.validate(password)
        }));
    }, []);

    const resetPassword = async () => {
        try {
            const response = await CallApi({
                method: 'POST',
                url: `${baseApiUrl}/api/v1/auth/forgot-password/reset`,
                data: {
                    mobileNumber: mobileNumber,
                    verificationCode: otp,
                    newPassword: password
                }
            })
            if (response.status === 200) {
                toast.success(response?.data?.message);
                return true;
            } else {
                toast.error(response.data.message);
                return false
            }
        } catch (error) {
            console.log(error)
            toast.error('Something went wrong')
            return false
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = validationRules.every(rule => rule.validate(password));
        const passwordsMatch = password === confirmPassword;

        if (isValid && passwordsMatch) {
            // Handle password update
            const success = await resetPassword();
            if(success){
                passwordUpdated(true);
            }
        }
    };

    const validationStatus = validatePassword(isFocused === 'password' ? password : confirmPassword);
    const isFormValid = validationStatus.every(rule => rule.isValid) &&
        password === confirmPassword &&
        password.length > 0;

    const isLogin = (params) => {
        isSignUp(true)
    }

    // const handleChangePassword = (params) => {
    //     passwordUpdated(true)
    // }

    const handleInputFocus = (field) => {
        if (blurTimeoutId) {
            clearTimeout(blurTimeoutId);
        }
        setIsFocused(field);
    };

    const handleInputBlur = () => {
        const timeoutId = setTimeout(() => {
            setIsFocused('');
        }, 100);
        setBlurTimeoutId(timeoutId);
    };

    const handleEyeMouseDown = (e) => {
        e.preventDefault();
        if (blurTimeoutId) {
            clearTimeout(blurTimeoutId);
        }
    };

    return (
        <div className={styles.container}>
            <ToastContainer />
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <img
                        loading="lazy"
                        src="/Assets/Modules/SignUp/Logo.svg"
                        alt="Company Logo"
                        className={styles.logo}
                    />
                    <div className={styles.titleWrapper}>
                        <h1 className={styles.title}>Create New Password</h1>
                        <p className={styles.subtitle}>
                            Please enter your new password. Your password must be between
                            6 and 20 characters long for security.
                        </p>
                    </div>
                </div>

                <form onSubmit={handleSubmit} className={styles.formWrapper}>
                    <label>Password<span class={styles.requiredMark}>*</span></label>
                    <section
                        onChange={(e) => setPassword(e.target.value)}
                        onFocus={() => handleInputFocus('password')}
                        onBlur={handleInputBlur}
                        className={loginStyle.passwordStyle}>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            placeholder="Create a password"
                            value={password}
                            aria-required="true"
                            className={styles.input}
                        />
                        {showPassword ? (
                            <LuEye
                                onMouseDown={handleEyeMouseDown}
                                onClick={() => setShowPassword(!showPassword)}
                                className={loginStyle.eyeStyle}
                            />
                        ) : (
                            <LuEyeOff
                                onMouseDown={handleEyeMouseDown}
                                onClick={() => setShowPassword(!showPassword)}
                                className={loginStyle.eyeStyle}
                            />
                        )}
                    </section>
                    <label>Re-enter Password<span class={styles.requiredMark}>*</span></label>
                    <section
                        onFocus={() => handleInputFocus('confirmPassword')}
                        onBlur={handleInputBlur}
                        className={loginStyle.passwordStyle}>
                        <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            id="confirmPassword"
                            placeholder="Re-enter your password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}

                            aria-required="true"
                            className={styles.input}
                        />
                        {showConfirmPassword ? (
                            <LuEye
                                onMouseDown={handleEyeMouseDown}
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                className={loginStyle.eyeStyle}
                            />
                        ) : (
                            <LuEyeOff
                                onMouseDown={handleEyeMouseDown}
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                className={loginStyle.eyeStyle}
                            />
                        )}
                    </section>
                    {isFocused !== '' && <div className={styles.validationList} role="alert" aria-live="polite">
                        {validationStatus.map((rule) => {
                            const currentField = isFocused === 'password' ? password : confirmPassword;
                            return (
                                <div key={rule.id} className={styles.validationItem}>
                                    <img
                                        src={currentField.length === 0 ? rule.info : rule.isValid ? rule.validIcon : rule.inValidIcon}
                                        alt=""
                                        className={styles.validationIcon}
                                    />
                                    <span className={`${styles.validationText} ${currentField.length === 0 ? styles.validationTextIdle : rule.isValid ? styles.validationSuccess : styles.validationError
                                        }`}>
                                        {rule.text}
                                    </span>
                                </div>
                            )
                        })}
                    </div>}

                    <div className={styles.footer}>
                        <button
                            type="submit"
                            className={styles.submitButton}
                            disabled={!isFormValid}
                        // onClick={handleChangePassword}
                        >
                            Create Password
                        </button>
                        <div className={styles.loginPrompt}>
                            <span className={styles.loginText}>Remember Password?</span>
                            <span onClick={isLogin} className={styles.loginLink}>Login</span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};