import { useNavigate } from "react-router-dom";
import styles from "./LandingPage.module.css";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const LandingPage = () => {
    const navigate = useNavigate();

    const handleButtonClick = (button) => {
        const data = { button };
        const queryString = new URLSearchParams(data).toString();
        navigate(`/auth/signup?${queryString}`);
    };

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Connect",
        "description": "Connect brings together healthcare, home services, pet care, entertainment, events, and construction under one powerful platform.",
        "url": "https://connectsolutionss.in",
        "sameAs": [
            "https://www.facebook.com/profile.php?id=61560584010523",
            "https://www.linkedin.com/company/connectsolutionss/",
            "https://x.com/connect_solutns",
            "https://www.instagram.com/connect_solutionss"
        ]
    };

    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <header
                id="home"
                className={styles.topSection}
                style={{
                    backgroundImage: `url("/Assets/Modules/Home/LandingImage.png")`,
                }}
            >
                <main className={styles.topSectionContent}>
                    <h1 className={styles.introTagline}>
                        Connect the future of simplified living!
                    </h1>
                    <p className={styles.introText}>
                        Discover a world where convenience meets excellence.
                        Connect brings together healthcare, home services, pet care, entertainment, events, and construction under one powerful platform.
                    </p>
                    <nav className={styles.btnLayout}>
                        <button
                            onClick={() => handleButtonClick('signup')}
                            aria-label="Partner with us"
                            className={styles.getStartedBtn}>
                            Partner with us
                        </button>
                        <button
                            onClick={() => handleButtonClick('login')}
                            aria-label="Partner Login"
                            className={[styles.getStartedBtn, styles.partLoginBtn].join(" ")}
                        >
                            Partner Login
                        </button>
                    </nav>
                </main>

                <footer className={styles.socialmediaLinks}>
                    <a
                        href=" https://www.facebook.com/profile.php?id=61560584010523&mibextid=LQQJ4d"
                        target="_blank"
                        rel="noreferrer noopener"
                        className={styles.topSocialmediaIcon}
                    >
                        <LazyLoadImage
                            alt="Facebook"
                            src='/Assets/Modules/Home/SocialMedia/facebook.svg'
                            className={styles.iconStyle}
                        />
                    </a>
                    <a
                        href=" https://www.linkedin.com/company/connectsolutionss/"
                        target="_blank"
                        rel="noreferrer noopener"
                        className={styles.topSocialmediaIcon}
                    >
                        <LazyLoadImage
                            alt="Linkedin"
                            src='/Assets/Modules/Home/SocialMedia/linkedin.svg'
                            className={styles.iconStyle}
                        />
                    </a>
                    <a
                        href="https://x.com/connect_solutns?s=21&t=xeqBgjMxSin4lFC3XGTAKg "
                        target="_blank"
                        rel="noreferrer noopener"
                        className={styles.topSocialmediaIcon}
                    >
                        <LazyLoadImage
                            alt="X"
                            src='/Assets/Modules/Home/SocialMedia/x.svg'
                            className={styles.iconStyle}
                        />
                    </a>
                    <a
                        href="https://www.instagram.com/connect_solutionss?igsh=bTZ1YXNpc2V6c2Vh&utm_source=qr "
                        target="_blank"
                        rel="noreferrer noopener"
                        className={styles.topSocialmediaIcon}
                    >
                        <LazyLoadImage
                            alt="Instagram"
                            src='/Assets/Modules/Home/SocialMedia/Insta.svg'  // added instagram svg
                            className={styles.iconStyle}
                        />
                    </a>
                </footer>
            </header>
        </>
    );
};