import React, { useEffect, useState } from 'react';
import styles from './AddresssDetails.module.css';
import { FormInput } from '../utils/FormInput';
import { FormSelect } from '../utils/FormSelect';

export function AddressDetails({onValidation, showErrors}) {
    const [sameAddress, setSameAddress] = useState(false);
    const [permanentAddress, setPermanentAddress] = useState({
        addressLine1: '',
        addressLine2: '',
        landmark: '',
        state: '',
        city: '',
        pincode: ''
    });
    const [currentAddress, setCurrentAddress] = useState({
        addressLine1: '',
        addressLine2: '',
        landmark: '',
        state: '',
        city: '',
        pincode: ''
    });
    const [errors, setErrors] = useState({
        permanent: {},
        current: {}
    });
    

    useEffect(() => {
        validateForm();
    }, [permanentAddress, currentAddress, sameAddress]);

    const validateField = (field, value) => {
        if (field === 'pincode' && !/^\d{6}$/.test(value)) {
            return 'Pincode must be 6 digits';
        }
        if (['addressLine1', 'addressLine2', 'landmark'].includes(field) && !value.trim()) {
            return 'This field is required';
        }
        return '';
    };

    const validateForm = () => {
        const newErrors = {
            permanent: {},
            current: {}
        };
        let isValid = true;

        Object.keys(permanentAddress).forEach(field => {
            const error = validateField(field, permanentAddress[field]);
            if (error) {
                newErrors.permanent[field] = error;
                isValid = false;
            }
        });

        if (!sameAddress) {
            Object.keys(currentAddress).forEach(field => {
                const error = validateField(field, currentAddress[field]);
                if (error) {
                    newErrors.current[field] = error;
                    isValid = false;
                }
            });
        }

        setErrors(newErrors);
        onValidation(isValid);
    };

    const handleAddressChange = (type, field, value) => {
        const newErrors = { ...errors };
        if (type === 'permanent') {
            setPermanentAddress(prev => ({ ...prev, [field]: value }));
            newErrors.permanent[field] = '';
            if (sameAddress) {
                setCurrentAddress(prev => ({ ...prev, [field]: value }));
                newErrors.current[field] = '';
            }
        } else {
            setCurrentAddress(prev => ({ ...prev, [field]: value }));
            newErrors.current[field] = '';
        }
        console.log(newErrors,'newErrors')
        setErrors(newErrors);
    };

    const handleSameAddressChange = (e) => {
        setSameAddress(e.target.checked);
        if (e.target.checked) {
            setCurrentAddress(permanentAddress);
            setErrors(prev => ({ ...prev, current: {} }));
        }
    };

    const renderAddressFields = (type, handleChange) => (
        <>
            <FormInput
                label="Address line 1"
                required
                placeholder="House no./Building no."
                id={`${type}AddressLine1`}
                value={type === 'permanent' ? permanentAddress.addressLine1 : currentAddress.addressLine1}
                onChange={(e) => handleAddressChange(type, 'addressLine1', e.target.value)}
                error={showErrors ? errors[type].addressLine1 : ''}
            />
            <FormInput
                label="Address Line 2"
                required
                placeholder="Building name/Area/Locality"
                id={`${type}AddressLine2`}
                value={type === 'permanent' ? permanentAddress.addressLine2 : currentAddress.addressLine2}
                onChange={(e) => handleAddressChange(type, 'addressLine2', e.target.value)}
                error={showErrors ? errors[type].addressLine2 : ''}
            />
            <FormInput
                label="Landmark"
                placeholder="Building name/Area/Locality"
                id={`${type}Landmark`}
                value={type === 'permanent' ? permanentAddress.landmark : currentAddress.landmark}
                onChange={(e) => handleAddressChange(type, 'landmark', e.target.value)}
                error={showErrors ? errors[type].landmark : ''}
            />
            <FormSelect
                label="State"
                required
                placeholder="Select your state"
                id={`${type}State`}
                value={type === 'permanent' ? permanentAddress.state : currentAddress.state}
                onChange={(e) => handleAddressChange(type, 'state', e.target.value)}
                error={showErrors ? errors[type].state : ''}
            />
            <FormSelect
                label="City"
                required
                placeholder="Select your city"
                id={`${type}City`}
                value={type === 'permanent' ? permanentAddress.city : currentAddress.city}
                onChange={(e) => handleAddressChange(type, 'city', e.target.value)}
                error={showErrors ? errors[type].city : ''}
            />
            <FormInput
                label="Pincode"
                required
                placeholder="Enter your pincode"
                id={`${type}Pincode`}
                type="number"
                value={type === 'permanent' ? permanentAddress.pincode : currentAddress.pincode}
                onChange={(e) => handleAddressChange(type, 'pincode', e.target.value)}
                error={showErrors ? errors[type].pincode : ''}
            />
        </>
    );

    return (
        <form className={styles.addressContainer}>
            <div className={styles.formWrapper}>
                <div className={styles.formContent}>
                    <div className={styles.formSection}>
                        <h2 className={styles.sectionTitle}>Permanent Address</h2>
                        {renderAddressFields('permanent')}

                        <div className={styles.checkboxContainer}>
                            <div className={styles.checkbox}>
                                <input
                                    type="checkbox"
                                    id="sameAddress"
                                    className={styles.checkboxInput}
                                    checked={sameAddress}
                                    onChange={handleSameAddressChange}
                                    aria-label="Current address same as permanent address"
                                />
                            </div>
                            <label htmlFor="sameAddress" className={styles.checkboxLabel}>
                                Current address same as permanent address
                            </label>
                        </div>

                        {!sameAddress && (
                            <div className={styles.currentAddressSection}>
                                <h2 className={styles.sectionTitle}>Current Address</h2>
                                {renderAddressFields('current')}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </form>
    );
}
