import React, { useState, useEffect, useCallback, useRef } from 'react';
import styles from './HomeCarousel.module.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';

const carouselData = [
    {
        id: 1,
        title: "Home Services",
        description: "Discover endless possibilities with CONNECT – create events, book services, donate blood, and connect with providers across diverse sectors, all that at your fingertips",
        icon: 'IoHome',
        color: "#4bcbbb",
        card: '/Assets/Modules/Home/CarouselCard/HomeServicesCard.svg'
    },
    {
        id: 2,
        title: "Petcare",
        description: "Find and book trusted service providers across multiple sectors with our comprehensive platform",
        icon: 'MdOutlinePets',
        color: "#e098c5",
        card: '/Assets/Modules/Home/CarouselCard/PetcareCard.svg'
    },
    {
        id: 3,
        title: "Entertainment",
        description: "Organize, promote, and manage your events seamlessly with our integrated event management tools",
        icon: 'GiMicrophone',
        color: "#9a406e",
        card: '/Assets/Modules/Home/CarouselCard/EntertainmentCard.svg'
    },
    {
        id: 4,
        title: "Healthcare",
        description: "Discover endless possibilities with CONNECT – create events, book services, donate blood, and connect with providers across diverse sectors, all that at your fingertips",
        icon: 'GiHeartBeats',
        color: "#2f2f6e",
        card: '/Assets/Modules/Home/CarouselCard/HealthcareCard.svg'
    },
    {
        id: 5,
        title: "Events",
        description: "Find and book trusted service providers across multiple sectors with our comprehensive platform",
        icon: 'BiSolidParty',
        color: "#fda834",
        card: '/Assets/Modules/Home/CarouselCard/EventsCard.svg'
    }, {
        id: 6,
        title: "Construction",
        description: "Organize, promote, and manage your events seamlessly with our integrated event management tools",
        icon: 'GiConstruction',
        color: "#fda834",
        card: '/Assets/Modules/Home/CarouselCard/ConstructionCard.svg'
    }
];

export const HomeCarousel = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [isAutoPlaying, setIsAutoPlaying] = useState(true);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const carouselTrackRef = useRef(null);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - carouselTrackRef.current.offsetLeft);
        setScrollLeft(carouselTrackRef.current.scrollLeft);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - carouselTrackRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Adjust the scroll speed
        carouselTrackRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => setIsDragging(false);
    const handleMouseLeaveDrag = () => setIsDragging(false);

    const handleTouchStart = (e) => {
        setIsDragging(true);
        setStartX(e.touches[0].pageX - carouselTrackRef.current.offsetLeft);
        setScrollLeft(carouselTrackRef.current.scrollLeft);
    };

    const handleTouchMove = (e) => {
        if (!isDragging) return;
        const x = e.touches[0].pageX - carouselTrackRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Adjust the scroll speed
        carouselTrackRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleTouchEnd = () => setIsDragging(false);

    const getOrderedSlides = useCallback(() => {
        const slides = [...carouselData];
        const centerIndex = Math.floor(slides.length / 2);
        const offset = centerIndex - activeIndex;

        return [...slides.slice(-offset), ...slides.slice(0, -offset)];
    }, [activeIndex]);

    const moveToIndex = useCallback((index) => {
        const newIndex = ((index % carouselData.length) + carouselData.length) % carouselData.length;
        setActiveIndex(newIndex);
    }, []);

    useEffect(() => {
        if (!isAutoPlaying) return;

        const interval = setInterval(() => {
            moveToIndex(activeIndex + 1);
        }, 5000);

        return () => clearInterval(interval);
    }, [activeIndex, moveToIndex, isAutoPlaying]);

    const handleMouseEnter = () => setIsAutoPlaying(true);
    const handleMouseLeave = () => setIsAutoPlaying(true);

    const orderedSlides = getOrderedSlides();

    const handleCardClick = (index) => {
        moveToIndex(index);
    };

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "ItemList",
        "itemListElement": carouselData.map((item, index) => ({
            "@type": "ListItem",
            "position": index + 1,
            "name": item.title,
            "description": item.description,
            "image": item.card
        }))
    };

    return (
        <section
            id="sectors"
            className={styles.carouselContainer}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <header className={styles.contentWrapper}>
                <h2 className={styles.title}>{'Simplify Your Life, All in One Place'}</h2>
                {/* <p className={styles.description}>{carouselData[activeIndex].description}</p> */}
                <p className={styles.description}>Discover endless possibilities with CONNECT – create events, book services, donate blood, and connect with providers across diverse sectors, all that at your fingertips!</p>
                <nav>
                    <button className={styles.exploreButton}>Explore our Sectors</button>
                </nav>
            </header>

            <main
                className={styles.carouselTrack}
                ref={carouselTrackRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeaveDrag}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                {orderedSlides.map((item, index) => {
                    const isCenter = index === Math.floor(orderedSlides.length / 2);
                    const offset = index - Math.floor(orderedSlides.length / 2);
                    const zIndex = -Math.abs(offset);
                    // const opacity = 1 - Math.abs(offset) * 0.3;
                    const scale = 1 - Math.abs(offset) * 0.1;
                    const rotateY = offset * -10;

                    return (
                        <article
                            key={item.id}
                            className={`${styles.carouselCard} ${isCenter ? styles.active : ''}`}
                            style={{
                                transform: `translateX(${offset * 80}%) scale(${scale}) rotateY(${rotateY}deg)`,
                                zIndex,
                                // opacity,
                            }}
                            role="group"
                            aria-roledescription="slide"
                            aria-label={`${index + 1} of ${orderedSlides.length}`}
                            onClick={() => handleCardClick(carouselData.findIndex(slide => slide.id === item.id))}
                        >
                            <LazyLoadImage
                                src={item.card}
                                alt={item.title}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                            />
                        </article>
                    );
                })}
            </main>


            <nav className={styles.dotsContainer} role="tablist">
                {carouselData.map((_, index) => (
                    <button
                        key={index}
                        className={`${styles.dot} ${index === activeIndex ? styles.active : ''}`}
                        onClick={() => moveToIndex(index)}
                        aria-label={`Go to slide ${index + 1}`}
                        role="tab"
                        aria-selected={index === activeIndex}
                        aria-controls={`slide-${index}`}
                    />
                ))}
            </nav>
        </section>
    );
};