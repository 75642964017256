import React from 'react';
import styles from '../ProfileStep/AddresssDetails.module.css';

export function FormSelect({ label, required, placeholder, id, options = [] , error }) {
    return (
        <div className={styles.inputGroup}>
            <label htmlFor={id} className={styles.inputLabel}>
                {label}
                {required && <span className={styles.required}>*</span>}
            </label>
            <div>
                <select
                    id={id}
                    className={styles.inputField}
                    required={required}
                    aria-label={label}
                >
                    <option value="">{placeholder}</option>
                    {options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
            {error && <p className={styles.errorText}>{error}</p>}
        </div>
    );
}