import React, { useRef, useState } from 'react';
import styles from './IdentityDocuments.module.css';
import Modal from '../../utils/modal/Modal';
import SignupSuccess from './SignupSuccess';
import { useNavigate } from 'react-router-dom';

export const IdentityDocuments = () => {
    const [documents, setDocuments] = useState([]);
    const [addMore, setAddMore] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [currentDoc, setCurrentDoc] = useState({
        type: '',
        idNumber: '',
        file: null,
    });
    const [errors, setErrors] = useState({
        type: '',
        idNumber: '',
        file: ''
    });
    const fileInputRef = useRef(null);
    const navigate = useNavigate();

    const handleFileUpload = (event) => {

        if (!currentDoc.type) {
            setErrors(prev => ({ ...prev, type: 'Please select a document type' }));
        }
        if (!currentDoc.idNumber) {
            setErrors(prev => ({ ...prev, idNumber: 'Please enter an ID number' }));
        }

        if (!currentDoc.type || !currentDoc.idNumber) {
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            return;
        }

        const file = event.target.files[0];

        if (file && file.size <= 500 * 1024 * 1024) {
            setCurrentDoc(prev => ({
                ...prev,
                file: {
                    name: file.name,
                    size: `${Math.round(file.size / 1024 / 1024)} MB`,
                    url: URL.createObjectURL(file)
                }
            }));
            setDocuments([...documents, {
                type: currentDoc.type,
                idNumber: currentDoc.idNumber,
                file: {
                    name: file.name,
                    size: `${Math.round(file.size / 1024 / 1024)} MB`,
                    url: URL.createObjectURL(file)
                }
            }]);
            setAddMore(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (currentDoc.type && currentDoc.idNumber && currentDoc.file) {
            setDocuments([...documents, currentDoc]);
            setCurrentDoc({ type: '', idNumber: '', file: null });
            setAddMore(false);
            setShowModal(true);
        }
    };

    const handleDelete = (index) => {
        setDocuments(documents.filter((_, i) => i !== index));
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && file.size <= 500 * 1024 * 1024) {
            setCurrentDoc(prev => ({
                ...prev,
                file: {
                    name: file.name,
                    size: `${Math.round(file.size / 1024 / 1024)} MB`,
                    url: URL.createObjectURL(file)
                }
            }));
        }
    };

    const modalHeader = 'Profile Setup Completed';
    const modalSubHeader = 'Your profile details have been successfully added to your account. You can Edit/Delete those details whenever required.';
    const modalButton = 'Go to Dashboard';

    const renderDocUploader = () => (
        <div className={styles.uploaderContainer}>
            <div className={styles.label}>Upload</div>
            <div
                className={styles.dropzone}
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
                onClick={() => document.getElementById('fileUpload').click()}
                role="button"
                tabIndex="0"
            >
                <input
                    type="file"
                    id="fileUpload"
                    ref={fileInputRef}
                    className={styles.fileInput}
                    onChange={handleFileUpload}
                    accept=".jpg,.png,.gif,.pdf,.svg,.xml"
                    aria-label="Upload file"
                />
                <img src="/Assets/Modules/ProfileStep/upload.svg" alt="" className={styles.uploadIcon} />
                <div className={styles.uploadContent}>
                    <div className={styles.uploadText}>
                        Drop your file here or <label className={styles.browseLink}>Browse</label>
                    </div>
                    <div className={styles.uploadHint}>
                        JPG, PNG, GIF, PDF, SVG, XML (Max 500 MB)
                    </div>
                </div>
            </div>
        </div>
    )

    const renderDocumentPreview = () => (
        <div className={styles.documentPreview}>
            <div className={styles.fileInfo}>
                <img src='/Assets/Modules/ProfileStep/pdfIcon.svg' alt="" className={styles.thumbnail} />
                <div className={styles.fileDetails}>
                    <div className={styles.fileName}>{currentDoc.file.name}</div>
                    <div className={styles.fileSize}>{currentDoc.file.size}</div>
                </div>
            </div>
            <div className={styles.actions}>
                <button
                    type="button"
                    className={styles.actionButton}
                    onClick={() => window.open(currentDoc.file.url)}
                    aria-label="Download document"
                >
                    <img src="/Assets/Modules/ProfileStep/download.svg" alt="" className={styles.actionIcon} />
                </button>
                <button
                    type="button"
                    className={styles.actionButton}
                    onClick={handleDelete}
                    aria-label="Delete document"
                >
                    <img src="/Assets/Modules/ProfileStep/delete.svg" alt="" className={styles.actionIcon} />
                </button>
            </div>
        </div>
    );


    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.header}>
                <h2 className={styles.title}>Identity Documents</h2>
                <button type="button" onClick={() => {
                    setAddMore(true);
                    setCurrentDoc({ type: '', idNumber: '', file: null });
                }} className={styles.addMore}>+ Add more</button>
            </div>

            {documents.map((doc, index) => (
                <>
                    <div className={styles.inputGroup}>
                        <label className={styles.label}>
                            Document type<span className={styles.required}>*</span>
                        </label>
                        <select
                            className={styles.input}
                            value={doc.type}
                            required
                            disabled
                        >
                            <option value="">Select identity document type</option>
                            <option value="aadhar">Aadhar Card</option>
                            <option value="pan">PAN Card</option>
                            <option value="passport">Passport</option>
                        </select>
                    </div>

                    <div className={styles.inputGroup}>
                        <label className={styles.label}>
                            ID Number<span className={styles.required}>*</span>
                        </label>
                        <input
                            type="text"
                            className={styles.input}
                            value={doc.idNumber}
                            required
                            disabled
                        />
                    </div>
                    <div key={index} className={styles.documentPreview}>
                        <div className={styles.fileInfo}>
                            {/* <img src={doc.file.url} alt="" className={styles.thumbnail} /> */}
                            <img src='/Assets/Modules/ProfileStep/pdfIcon.svg' alt="" className={styles.thumbnail} />
                            <div className={styles.fileDetails}>
                                <div className={styles.fileName}>{doc.file.name}</div>
                                <div className={styles.fileSize}>{doc.file.size}</div>
                            </div>
                        </div>
                        <div className={styles.actions}>
                            <button
                                type="button"
                                className={styles.actionButton}
                                onClick={() => window.open(doc.file.url)}
                                aria-label="Download document"
                            >
                                <img src="/Assets/Modules/ProfileStep/download.svg" alt="" className={styles.actionIcon} />
                            </button>
                            <button
                                type="button"
                                className={styles.actionButton}
                                onClick={() => handleDelete(index)}
                                aria-label="Delete document"
                            >
                                <img src="/Assets/Modules/ProfileStep/delete.svg" alt="" className={styles.actionIcon} />
                            </button>
                        </div>
                    </div>
                </>
            ))}
            {addMore &&
                <div>
                    <div className={styles.inputGroup}>
                        <label className={styles.label}>
                            Document type<span className={styles.required}>*</span>
                        </label>
                        <select
                            className={styles.input}
                            value={currentDoc.type}
                            onChange={(e) => {
                                setCurrentDoc({ ...currentDoc, type: e.target.value });
                                setErrors(prev => ({ ...prev, type: '' }));
                                if (fileInputRef.current) {
                                    fileInputRef.current.value = '';
                                }
                            }}
                            required
                        >
                            <option value="">Select identity document type</option>
                            <option value="aadhar">Aadhar Card</option>
                            <option value="pan">PAN Card</option>
                            <option value="passport">Passport</option>
                        </select>
                        {errors.type && <div className={styles.errorText}>{errors.type}</div>}
                    </div>
                    <div className={styles.inputGroup}>
                        <label className={styles.label}>
                            ID Number<span className={styles.required}>*</span>
                        </label>
                        <input
                            type="text"
                            className={styles.input}
                            value={currentDoc.idNumber}
                            onChange={(e) => {
                                setCurrentDoc(prev => ({ ...prev, idNumber: e.target.value }));
                                setErrors(prev => ({ ...prev, idNumber: '' }));
                                if (fileInputRef.current) {
                                    fileInputRef.current.value = '';
                                }
                            }}
                            placeholder="XXXX-XXXX-XXXX"
                            required
                        />
                        {errors.idNumber && <div className={styles.errorText}>{errors.idNumber}</div>}
                    </div>

                    {currentDoc.file ? renderDocumentPreview() : renderDocUploader()}
                </div>}
            <button type="submit" className={styles.submitButton}>
                Complete profile
            </button>
            <Modal show={showModal} isClosable={true} onClose={() => setShowModal(false)}>
                <SignupSuccess modalHeader={modalHeader} modalSubHeader={modalSubHeader} modalButton={modalButton} onBtnClick={() => navigate('/')} />
            </Modal>
        </form>
    );
};