import React from 'react';
import styles from './DatePicker.module.css';

export const DatePicker = ({ value, onChange, required }) => {
    return (
        <div className={styles.datePickerWrapper}>
            <input
                type="date"
                value={value}
                onChange={onChange}
                className={styles.datePicker}
                required={required}
                aria-required={required}
            />
            <img
                src='/Assets/Modules/ProfileStep/calendar.svg'
                className={styles.imageStyle}
                alt='calendar'
            />
        </div>
    );
};