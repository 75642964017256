import React from 'react';
import styles from './Privacy.module.css';
import Header from '../Home/Header';
import { Footer } from '../Home/Footer';
import PrivacyPolicy from '../../Authentication/Signup/Documents/PrivacyPolicy/PrivacyPolicy';

// const PolicySection = ({ title, content, listItems }) => (
//     <>
//         <span className={styles.textBold}>{title}</span>
//         <br />
//         <span className={styles.textRegular}>{content}</span>
//         {listItems && (
//             <ul className={styles.list}>
//                 {listItems.map((item, index) => (
//                     <li key={index} className={styles.listItem}>
//                         <span className={styles.textLight}>{item}</span>
//                     </li>
//                 ))}
//             </ul>
//         )}
//         <br />
//     </>
// );

// const policySections = [
//     {
//         title: '1. Information We Collect',
//         content: 'We may collect the following types of information:',
//         listItems: [
//             'Personal Information: Name, email address, phone number, mailing address, and any other information you voluntarily provide when contacting us or signing up for services.',
//             'Non-Personal Information: Browser type, operating system, IP address, and browsing behavior collected through cookies and other tracking technologies.',
//             'Sector-Specific Information: Depending on the service you use, we may collect additional details such as pet preferences for pet care, project details for construction, or event requirements for event planning.'
//         ]
//     },
//     {
//         title: '2. How We Use Your Information',
//         content: 'We use your information for purposes including but not limited to:',
//         listItems: [
//             'Providing and managing services tailored to your sector-specific needs.',
//             'Responding to inquiries and offering customer support.',
//             'Sending updates, newsletters, and promotional materials (with your consent).',
//             'Improving our website, services, and user experience.',
//             'Complying with legal obligations.'
//         ]
//     },
//     {
//         title: '3. Sharing Your Information',
//         content: 'We do not sell or rent your information to third parties. However, we may share your information in the following circumstances:',
//         listItems: [
//             'With Service Providers: Third-party vendors assisting in providing services.',
//             'For Legal Compliance: To comply with legal obligations or protect our rights.',
//             'With Your Consent: When you explicitly agree to share your information.'
//         ]
//     },
//     {
//         title: '4. Cookies and Tracking Technologies',
//         content: 'We use cookies and similar technologies to enhance your browsing experience. You can manage or disable cookies through your browser settings. However, doing so may affect the functionality of our website.',
//         listItems: []
//     },
//     {
//         title: '5. Data Security',
//         content: 'We implement appropriate technical and organizational measures to protect your information. Despite our efforts, no security measures are completely foolproof, and we cannot guarantee absolute security.',
//         listItems: []
//     },
//     {
//         title: '6. Third-Party Links',
//         content: 'Our website may contain links to third-party websites. We are not responsible for their privacy practices. We encourage you to review the privacy policies of any third-party websites you visit.',
//         listItems: []
//     },
//     {
//         title: '7. Your Rights',
//         content: 'Depending on your location, you may have the right to:',
//         listItems: [
//             'Access, update, or delete your personal information.',
//             'Withdraw consent for data processing.',
//             'Opt-out of marketing communications.'
//         ]
//     },
//     {
//         title: '8. Changes to This Privacy Policy',
//         content: 'We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated "Effective Date."',
//         listItems: []
//     },
//     {
//         title: '9. Contact Us',
//         content: 'These Privacy Policies are governed by and construed in accordance with the laws of [Your Country/State]. For inquiries or concerns, please contact us at [Contact Email].',
//         listItems: []
//     }
// ];

export const Privacy = () => {
    return (
        <>
            <Header />
            <div className={styles.container}>
                <header className={styles.header}>
                    <div className={styles.headerContent}>
                        <div className={styles.headerWrapper}>
                            <div className={styles.label}>LEGAL</div>
                            <div className={styles.title}>Privacy Policy</div>
                            <div className={styles.subtitle}>
                                By using our services, you agree to our Privacy Policy, ensuring
                                a secure and transparent experience.
                            </div>
                        </div>
                    </div>
                </header>

                {/* <div className={styles.content}>
                    <div className={styles.policyText}>
                        <span className={styles.textRegular}>Effective Date:</span>
                        <span className={styles.textBold}> [Insert Date]</span>
                        <br /><br />

                        {policySections.map((section, index) => (
                            <PolicySection
                                key={index}
                                title={section.title}
                                content={section.content}
                                listItems={section.listItems}
                            />
                        ))}
                    </div>

                    <div className={styles.scrollWrapper}>
                        <div className={styles.scrollContent}>
                            <div className={styles.scrollBar} />
                        </div>
                    </div>
                </div> */}
                <div className={styles.childContent}>
                    <PrivacyPolicy hide/>
                </div>
            </div>
            <Footer />
        </>
    );
}