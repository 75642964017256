import React from 'react';
import styles from './Refund.module.css';
import Header from '../Home/Header';
import { Footer } from '../Home/Footer';
import TermOfUse from '../../Authentication/Signup/Documents/TermOfUse/TermOfUse';

// const policyData = [
//     {
//         title: "1. General Policy",
//         items: [
//             "Refunds and returns are applicable only for eligible services and subject to the specific terms outlined for each sector.",
//             "Requests must be submitted within the specified timeframe for the service category."
//         ]
//     },
//     {
//         title: "2. Sector-Specific Guidelines",
//         items: [
//             "Healthcare Services: Refunds are not applicable for completed consultations or treatments. However, if a scheduled appointment is canceled at least 24 hours in advance, you may qualify for a refund.",
//             "Entertainment & Events: Deposits or payments for events are non-refundable once confirmed. Partial refunds may be considered in cases of cancellations due to unforeseen circumstances, subject to approval.",
//             "Pet Care Services: Refunds are available for bookings canceled at least 48 hours before the scheduled service. Refunds will not be issued for no-shows or same-day cancellations.",
//             "Home Services: Refunds for home maintenance and repair services will only be issued if the service is canceled prior to the technician's visit. If the service is deemed incomplete or unsatisfactory, a review will be conducted for potential partial refunds.",
//             "Construction Services: Due to the nature of construction projects, refunds are not typically offered once the work has commenced. Exceptions may apply for services not rendered as per the agreement."
//         ]
//     },
//     {
//         title: "3. Refund Process",
//         items: [
//             "Eligible refunds will be processed within [Insert Number] business days of approval.",
//             "Refunds will be issued using the original payment method unless otherwise agreed.",
//             "Any applicable service or transaction fees may be deducted from the refund amount."
//         ]
//     },
//     {
//         title: "4. How to Request a Refund",
//         content: "To request a refund, please contact us via:",
//         items: [
//             "Email: [Insert Email Address]",
//             "Phone: [Insert Phone Number]",
//             "Form: [Insert Link to Refund Request Form]",
//             "Your name and contact information.",
//             "Service details (sector, date, and description).",
//             "Reason for the refund request."
//         ]
//     },
//     {
//         title: "5. Non-Refundable Items",
//         content: "The following are non-refundable:",
//         items: [
//             "Services fully delivered as per the agreement.",
//             "Deposits for services or projects in progress.",
//             "Administrative or consultation fees."
//         ]
//     },
//     {
//         title: "6. Changes to This Policy",
//         content: "We reserve the right to modify this Return & Refund Policy at any time. Changes will be effective when updated on this page."
//     }
// ];

export default function Refund() {
    return (
        <>
        <Header/>
        <div className={styles.policyContainer}>
            <header className={styles.headerSection}>
                <div className={styles.headerContent}>
                    <div className={styles.headerTextWrapper}>
                        <div className={styles.categoryLabel}>LEGAL</div>
                        <h1 className={styles.mainTitle}>Return & Refunds</h1>
                        <p className={styles.subtitle}>
                            Returns and refunds are processed as per our policy guidelines;
                            please review for details.
                        </p>
                    </div>
                </div>
            </header>

            {/* <main className={styles.policyContent}>
                <article className={styles.policyText}>
                    <span className={styles.textRegular}>Effective Date: </span>
                    <span className={styles.textBold}>[Insert Date]</span>

                    <p className={styles.textRegular}>
                        At Connect Solutions, we are dedicated to providing quality services across
                        healthcare, entertainment, events, pet care, home services, and construction.
                        We understand that situations may arise where you may need to request a return
                        or refund. This policy outlines the conditions under which returns and refunds
                        are handled.
                    </p>

                    {policyData.map((section, index) => (
                        <section key={index}>
                            <h2 className={styles.textBold}>{section.title}</h2>
                            {section.content && <p className={styles.textRegular}>{section.content}</p>}
                            {section.items && (
                                <ul>
                                    {section.items.map((item, itemIndex) => (
                                        <li key={itemIndex}>
                                            <span className={styles.textLight}>{item}</span>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </section>
                    ))}
                </article>

                <div className={styles.scrollBarContainer}>
                    <div className={styles.scrollBarWrapper}>
                        <div className={styles.scrollBarTrack} />
                    </div>
                </div>
            </main> */}
            <div className={styles.childContent}>
                    <TermOfUse hide/>
            </div>
        </div>
        <Footer/>
        </>
    );
}