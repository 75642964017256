import React from 'react';
import styles from './SignupSuccess.module.css';
import { Link } from 'react-router-dom';

export default function SignupSuccess({ modalHeader, modalSubHeader, modalButton, onBtnClick, btnShow = true }) {

    const handleClick = () => {
        onBtnClick(true)
    }


    return (
        <section className={styles.successSection}>
            <div className={styles.container}>
                <div className={styles.contentWrapper}>
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/fb60b6f73fe84ee99aaecdaf0cb76d4f/5ad476f5f84ceca7eabca0a1e88b4f4e55c27cc2ce27cd5e68b427dc61502ecb?apiKey=fb60b6f73fe84ee99aaecdaf0cb76d4f&"
                        className={styles.successIcon}
                        alt="Success checkmark icon"
                    />
                    <div className={styles.textContent}>
                        <h1 className={styles.title}>
                            {modalHeader}
                        </h1>
                        <p className={styles.description}>
                            {modalSubHeader}
                        </p>
                    </div>
                </div>
                {btnShow && <Link className={styles.buttonWrapper}>
                    <button
                        className={styles.dashboardButton}
                        onClick={handleClick}
                        tabIndex={0}
                    >
                        {modalButton}
                    </button>
                </Link>}
            </div>
        </section>
    );
}