import React, { useState } from 'react';
import styles from './BlogList.module.css';
import ReactIcon from '../../utils/ReactIcon';
import { getCategoryColor } from '../BlogSection';
import Header from '../Header';
import { Footer } from '../Footer';
import { blogdata } from './Blogdata';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const initialBlogs = {
    recent: [
        blogdata[blogdata.length - 1],
        blogdata[blogdata.length - 2],
        blogdata[blogdata.length - 3]
    ],
    popular: [
        ...blogdata
    ]
};

const categories = [
    { id: 'all', name: 'All Blogs' },
    { id: 'construction', name: 'Construction' },
    { id: 'healthcare', name: 'Healthcare' },
    { id: 'homeservices', name: 'Home Services' },
    { id: 'petcare', name: 'Petcare' },
    { id: 'entertainment', name: 'Entertainment' },
    { id: 'events', name: 'Events' }
];

export const BlogList = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    // const [blogs, setBlogs] = useState(initialBlogs);
    const blogs = initialBlogs;
    const navigate = useNavigate();

    const filterBlogs = (blogList) => {
        return blogList.filter(blog => {
            const matchesSearch = blog.title.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesCategory = selectedCategory === 'all' || blog.category.toLowerCase().replace(/\s+/g, '') === selectedCategory;
            return matchesSearch && matchesCategory;
        });
    };

    const filteredRecentBlogs = filterBlogs(blogs.recent);
    const filteredPopularBlogs = filterBlogs(blogs.popular);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const handleCategorySelect = (categoryId) => {
        setSelectedCategory(categoryId);
        setCurrentPage(1);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const renderBlogCards = (blog) => (
        <article key={blog.id} className={styles.blogCard} itemScope itemType="http://schema.org/BlogPosting">
            <div className={styles.blogImageWrapper}>
                <img src={blog.image} alt="" className={styles.blogImage} itemProp="image" />
                <span className={styles.blogCategory} style={{ color: getCategoryColor(blog.category) }} itemProp="articleSection">{blog.category}</span>
            </div>
            <div className={styles.blogContent}>
                <div className={styles.blogMeta}>
                    <div className={styles.authorInfo} itemProp="author" itemScope itemType="http://schema.org/Person">
                        <img src={blog.profilePic} alt="" className={styles.authorAvatar} />
                        <span className={styles.authorName} itemProp="name">{blog.name}</span>
                    </div>
                    <time className={styles.blogDate} itemProp="datePublished">{blog.date}</time>
                </div>
                <h3 className={styles.blogTitle} itemProp="headline">{blog.title}</h3>
                <p className={styles.blogDescription} itemProp="description">{blog.description}</p>
                <button onClick={() => {
                    const encodedData = encodeURIComponent(JSON.stringify(blog));
                    navigate(`/blogdetails?data=${encodedData}`);
                }}
                    className={styles.readMoreButton}>
                    Read More
                    <ReactIcon name='MdOutlineArrowOutward' size='24px' color='#122577' />
                </button>
            </div>
        </article>
    )

    const renderBlogCard = (blog, index, type) => {
        console.log('blog', blog);

        if (type === 'popular') {
            const blogsPerPage = 6;
            const startIndex = (currentPage - 1) * blogsPerPage;
            const endIndex = startIndex + blogsPerPage;
            console.log('index', index > startIndex && index <= endIndex);

            if (index > startIndex && index <= endIndex) {
                return renderBlogCards(blog);
            }
        } else {
            return renderBlogCards(blog);
        }
    }

    const renderPagination = () => {
        const totalPages = Math.ceil(filteredPopularBlogs.length / 6);
        const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

        return (
            <>
                <nav className={styles.desktopPagination} aria-label="Pagination">
                    <button
                        className={styles.paginationButton}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <img src="path/to/prev-icon.svg" alt="" />
                        <span>Previous</span>
                    </button>

                    <div className={styles.paginationNumbers}>
                        {pages.map(page => (
                            <button
                                key={page}
                                className={`${styles.pageNumber} ${page === currentPage ? styles.active : ''}`}
                                onClick={() => handlePageChange(page)}
                                aria-current={page === currentPage ? 'page' : undefined}
                            >
                                {page}
                            </button>
                        ))}
                    </div>

                    <button
                        className={styles.paginationButton}
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <span>Next</span>
                        <img src="path/to/next-icon.svg" alt="" />
                    </button>
                </nav>

                <nav className={styles.mobilePagination} aria-label="Pagination">
                    <button
                        className={styles.mobilePageButton}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        aria-label="Previous page"
                    >
                        <ReactIcon name='FaArrowLeft' size='24px' color='#667085' />
                    </button>
                    <span className={styles.mobilePageInfo}>Page {currentPage} of {totalPages}</span>
                    <button
                        className={styles.mobilePageButton}
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        aria-label="Next page"
                    >
                        <ReactIcon name='FaArrowRight' size='24px' color='#667085' />
                    </button>
                </nav>
            </>
        );
    };

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "Blog",
        "name": "Your Blog Name",
        "description": "Read our stories and helpful blogs on various topics.",
        "url": "https://yourwebsite.com/blog",
        "blogPost": filteredPopularBlogs.map(blog => ({
            "@type": "BlogPosting",
            "headline": blog.title,
            "description": blog.description,
            "image": blog.image,
            "author": { "@type": "Person", "name": blog.name },
            "datePublished": blog.date,
            "url": `https://connectsolutionss.in/blogs`,
            "articleSection": blog.category
        }))
    };

    return (
        <>
            <Helmet>
                <title>Blogs - Helpful Insights & Stories</title>
                <meta name="description" content="Read our stories and helpful blogs on various topics." />
                <link rel="canonical" href="https://connectsolutionss.in" />
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <Header />
            <div className={styles.blogListing}>
                <header className={styles.header}>
                    <div className={styles.headerContent}>
                        <div className={styles.headerText}>
                            <span className={styles.headerLabel}>BLOGS/ ARTICLES</span>
                            <h1 className={styles.headerTitle}>Helpful Insights & Stories</h1>
                            <p className={styles.headerDescription}>Read our stories and helpful blogs!</p>
                        </div>

                        <form className={styles.searchForm} role="search">
                            <label htmlFor="blogSearch" className={styles.visuallyHidden}>
                                Search blogs
                            </label>
                            <div className={styles.searchInput}>
                                <ReactIcon name='IoIosSearch' size='24px' color='#667085' />
                                <input
                                    type="search"
                                    id="blogSearch"
                                    placeholder="Type here"
                                    className={styles.searchField}
                                    value={searchTerm}
                                    onChange={handleSearch}
                                />
                                <button disabled className={styles.searchButton}>
                                    Search
                                </button>
                            </div>
                        </form>

                        <nav className={styles.categoryNav} aria-label="Blog categories">
                            <ul className={styles.categoryList}>
                                {categories.map(category => (
                                    <li key={category.id}>
                                        <button
                                            className={`${styles.categoryButton} ${selectedCategory === category.id ? styles.active : ''}`}
                                            onClick={() => handleCategorySelect(category.id)}
                                            aria-pressed={selectedCategory === category.id}
                                        >
                                            {category.name}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </header>

                <main className={styles.mainContent}>
                    {filteredRecentBlogs.length !== 0 && <section className={styles.recentPosts}>
                        <h2 className={styles.sectionTitle}>Recent blog posts</h2>
                        <div className={styles.blogGrid}>
                            {filteredRecentBlogs.map((blog, index) => renderBlogCard(blog, index, 'recent'))}
                        </div>
                    </section>}

                    {filteredPopularBlogs.length !== 0 && <section className={styles.popularPosts}>
                        <h2 className={styles.sectionTitle}>Popular blog posts</h2>
                        <div className={styles.blogGrid}>
                            {filteredPopularBlogs.map((blog, index) => renderBlogCard(blog, index, 'popular'))}
                        </div>
                    </section>}
                    {filteredPopularBlogs.length > 6 && renderPagination()}
                </main>
            </div>
            <Footer />
        </>
    );
}