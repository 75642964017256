import React, { useCallback, useEffect, useState } from 'react'
import styles from '../SignUp/SignUp.module.css'
import loginStyle from '../SignIn/Login.module.css'
import fpStyle from './ForgotPassword.module.css'
import OtpInput from '../../Authentication/Signup/Otp/OtpInput';
import Modal from '../../utils/modal/Modal';
import SignupSuccess from '../ProfileStep/SignupSuccess';
import { NewPassword } from './NewPassword';
import { toast, ToastContainer } from 'react-toastify';
import CallApi from '../Api/Callapi';

export default function ForgotPassword({ isForgotPassword }) {

    const [mobile, setMobile] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isnewPassword, setIsnewPassword] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState('');

    const [state, setState] = useState({
        modalHeader: '',
        modalSubHeader: '',
        modalButton: '',
        passwordVerified: false,
        errors: {
            otpFilled: false,
            otpVerified: false,
        },
    });
    const [seconds, setSeconds] = useState(60);
    const [isRunning, setIsRunning] = useState(false);

    const baseApiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD;

    useEffect(() => {
        let interval;

        if (isRunning && seconds > 0) {
            interval = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);
        } else if (seconds === 0) {
            setIsRunning(false);
        }

        // Cleanup interval when the component unmounts
        return () => clearInterval(interval);
    }, [isRunning, seconds]);

    const generateOTP = async () => {
        // Call your API to generate OTP
        try {
            const data = await CallApi({
                url: `${baseApiUrl}/api/v1/auth/verification/generate`,
                method: 'POST',
                data: {
                    mobileNumber: mobile,
                    useCase: "Forgot Password",
                    userType: "Service Provider"
                }
            });
            if (data.status === 200) {
                toast.success(data.message);
                return true;
            } else {
                toast.error(data.data.message);
                return false;
            }
        } catch (error) {
            toast.error('Something went wrong');
            return false;
        }
    }


    const handleSendCode = async (e) => {
        e.preventDefault();
        if (!isRunning) {
           const success = await generateOTP();
           if(success){
                setOtpSent(true);
                setIsRunning(true);
                setSeconds(60);
                }
        }
    };

    const handleResendCode = async (e) => {
        console.log('saasas', isRunning)
        e.preventDefault();
        if (!isRunning) {
            const success = await generateOTP();
            if (success) {
                setIsRunning(true);
                setSeconds(60);
            }
        }
    };

    useEffect(() => {
        if (state.errors.otpVerified && state.errors.otpFilled) {
            handleOtpVerification();
        }
    }, [state.errors.otpVerified]) 

    const handleOtpVerification = () => {
        setState((prevState) => ({
            ...prevState,
            modalHeader: 'Verification Successful!',
            modalSubHeader: 'The OTP has been successfully verified. You can now create a new password for your account.',
            modalButton: 'Create New Password',
            passwordVerified: true,
        }));
        setShowModal(true);
    }

    const handleVerify = (e) => {
        e.preventDefault();
        if (state.errors.otpVerified) {
            handleOtpVerification()
        }
    };

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedTime = `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;

    const handleOtpFilled = useCallback((isFill) => {
        setState((prevState) => ({
            ...prevState,
            errors: {
                ...prevState.errors,
                otpFilled: isFill,
            },
        }));
    }, []);

    const handleOtpVerified = useCallback((isVerify) => {
        setState((prevState) => ({
            ...prevState,
            errors: {
                ...prevState.errors,
                otpVerified: isVerify,
            },
        }));
    }, []);

    const isSignUp = () => {
        isForgotPassword(true);
    }
    const handleBtnClick = () => {
        setIsnewPassword(true);
        setShowModal(false);
    }

    const handleChangePassword = () => {
        setState((prevState) => ({
            ...prevState,
            modalHeader: 'Password Updated Successfully!',
            modalSubHeader: 'Your password has been successfully updated. You can now log in using your new password',
            modalButton: 'Login',
            passwordVerified: false
        }))
        setShowModal(true);
    }

    return (
        <div>
            <ToastContainer />
            {!isnewPassword ? <div>
                <form onSubmit={otpSent ? handleVerify : handleSendCode} className={styles.signupForm}>
                    <img
                        src="/Assets/Modules/SignUp/Logo.svg"
                        alt="Logo..."
                        className={fpStyle.iconStyle}
                    />
                    <h2>{!otpSent ? 'Forgot Password?' : 'OTP Verification'}</h2>
                    <h5 style={{
                        marginBottom: "1rem",
                    }}>Please enter your registered mobile number ending with +91-*******908 to receive the OTP.</h5>

                    {!otpSent ? <div className={fpStyle.inputGroup}>
                        <label className={fpStyle.label}>
                            Mobile No.
                            <span className={fpStyle.required}>*</span>
                        </label>
                        <div className={fpStyle.phoneInput}>
                            <div className={fpStyle.countryCode}>
                                <img src="/Assets/Modules/ProfileStep/india-flag.svg" alt="" className={fpStyle.flag} />
                                <span>+91</span>
                                <img src="/Assets/Modules/ProfileStep/chevron-down.svg" alt="" className={fpStyle.chevron} />
                            </div>
                            <input
                                type="tel"
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                className={fpStyle.phoneNumber}
                                required
                                aria-required="true"
                                pattern="[0-9]{10}"
                                placeholder='0000000000'
                            />
                        </div>
                    </div> :
                        <div>
                            <section className={fpStyle.info}>
                                <OtpInput
                                    onOtpComplete={handleOtpFilled}
                                    onOtpVerified={handleOtpVerified}
                                    mobileNumber={mobile}
                                    contStyle={fpStyle.otpInput}
                                    useCase='Forgot Password'
                                    otpValue={setOtp}
                                />
                            </section>
                            <div className={loginStyle.terms}>
                                <p onClick={handleResendCode} className={fpStyle.resendStyle}>
                                    <span className={fpStyle.sendCodeStyle}>Resend Code</span>
                                </p>
                                <p className={[loginStyle.footerText, fpStyle.timerStyle || ""].join(" ")}>
                                    <span className={fpStyle.timeStyle}>{formattedTime}</span>
                                </p>
                            </div>
                        </div>}
                    <button type="submit" className={styles.button}>
                        {!otpSent ? 'Send Code' : 'Verify'}
                    </button>

                    <p className={styles.footerText}>
                        Remember Password? <span className={loginStyle.forgotStyle} onClick={isSignUp} >Login</span>
                    </p>
                </form>
            </div> : <NewPassword isSignUp={isSignUp} passwordUpdated={handleChangePassword} otp={otp} mobileNumber={mobile} />}
            <Modal show={showModal} isClosable={true} onClose={() => setShowModal(false)}>
                <SignupSuccess modalHeader={state.modalHeader} modalSubHeader={state.modalSubHeader} modalButton={state.modalButton} onBtnClick={!state.passwordVerified ? isSignUp : handleBtnClick} />
            </Modal>
        </div>
    )
}
