import React, { useEffect, useState } from 'react';
import styles from './BlogDetails.module.css';
import Header from '../Header';
import { Footer } from '../Footer';
import { blogdata } from './Blogdata';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const BlogDetails = () => {
    const [blogData, setBlogData] = useState(blogdata[0]);

    const {
        title,
        name,
        date,
        image,
        profilePic,
        introduction,
        content
    } = blogData;
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const encodedData = searchParams.get('data');
        if (encodedData) {
            const decodedData = JSON.parse(decodeURIComponent(encodedData));
            console.log(decodedData, blogdata[decodedData.id - 1]);
            setBlogData(blogdata[decodedData.id - 1])
        }
    }, [location]);

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "headline": title,
        "image": image,
        "author": {
            "@type": "Person",
            "name": name
        },
        "datePublished": date,
        "articleBody": introduction + content.map(item => item.text).join(' ')
    };

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={introduction.substring(0, 20)} />
                <link rel="canonical" href="https://connectsolutionss.in/blogdetails" />
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <Header />
            <article className={styles.blogDetails}>
                <div className={styles.container}>
                    <header className={styles.blogHeader}>
                        <div className={styles.authorInfo}>
                            <img loading="lazy" src={profilePic} alt={name} className={styles.authorAvatar} />
                            <span className={styles.authorName}>{name}</span>
                            <time className={styles.postDate}>{date}</time>
                        </div>
                        <h1 className={styles.blogTitle}>{title}</h1>
                    </header>

                    <img loading="lazy" src={image} alt={title} className={styles.mainImage} />

                    <div className={styles.blogContent}>
                        <p className={styles.introduction}>{introduction}</p>

                        <section className={styles.benefitsSection}>
                            {content.map((item, index) => {
                                if (item.type === 'step') {
                                    return (
                                        <React.Fragment key={index}>
                                            <h2 className={styles.benefitTitle}>{item.title}</h2>
                                            <p className={styles.benefitText}>{item.content}</p>
                                        </React.Fragment>
                                    );
                                }
                                return null;
                            })}
                        </section>
                        <p className={styles.conclusion}>
                            <h2 className={styles.benefitTitle}>{'Conclusion'}</h2>
                            {content.find(item => item.type === 'conclusion')?.text}
                        </p>
                    </div>

                    <section className={styles.relatedPosts}>
                        {/* Keep the existing related posts section */}
                    </section>
                </div>
            </article>
            <Footer />
        </>
    );
};
