import React from "react";
import styles from "./SignUp.module.css";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import CarouselScreen from "./Carousel";
import { GiInfo } from "react-icons/gi";
import { IoIosInformationCircle } from "react-icons/io";
import Modal from "../../utils/modal/Modal";
import OtpVerification from "./OtpVerification";
import Profile from "./Profile";
import Login from "../SignIn/Login";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import phonsStyle from '../utils/QueryModal.module.css'
import loginStyle from '../SignIn/Login.module.css'
import { LuEye, LuEyeOff } from "react-icons/lu";
import { validationRules } from "../ForgotPassword/NewPassword";
import passwordStyle from '../ForgotPassword/NewPassword.module.css'
import Header from "../Home/Header";
import CallApi from "../Api/Callapi";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import CustomModal from "../utils/CustomModal";
import TermsAndConditions from "../../Authentication/Signup/Documents/TermAndConditions/TermAndConditions";
import PrivacyPolicy from "../../Authentication/Signup/Documents/PrivacyPolicy/PrivacyPolicy";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";

const Info = [
    {
        text: 'At least one capital Letter (A-Z)'
    },
    {
        text: 'At least one small letter (a-z)'
    },
    {
        text: 'At least one number (0-9)'
    },
    {
        text: 'Minimum 8 letters'
    },
]

const SignUp = () => {

    const [state, setState] = useState({
        fullName: "",
        mobileNumber: "",
        password: "",
        email: "",
        isProfileVerified: false,
        termsAccepted: false,
        errors: {
            fullName: "",
            mobileNumber: "",
            password: "",
            email: "",
            terms: "",
        },
    });
    const [modalShow, setModalShow] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isForgot, setIsForgot] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isPasswordFocused, setIsPasswordFocused] = useState(false);
    const [otpGenerated, setOtpGenerated] = useState(false);
    const [termsModal, setTermsModal] = useState(false);
    const [policy, setPolicy] = useState('');
    const navigate = useNavigate();
    const baseApiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD;

    const ProfileVerified = (value) => {
        setState((prevState) => ({
            ...prevState,
            isProfileVerified: value
        }));
        setModalShow(false)
    }

    const validateForm = () => {
        const newErrors = {};
        if (!state.fullName) newErrors.fullName = 'Full Name is required.';
        if (!state.mobileNumber) { newErrors.mobileNumber = 'Mobile Number is required.' }
        else if (!/^\d{10}$/.test(state.mobileNumber)) {
            newErrors.mobileNumber = 'Mobile Number must be exactly 10 digits.';
        }
        if (!state.email) {
            newErrors.email = 'Email is required.';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email)) {
            newErrors.email = 'Please enter a valid email address.';
        }
        if (!state.password) {
            newErrors.password = 'Password is required.';
        } else {
            if (!/[A-Z]/.test(state.password)) newErrors.password = 'At least one capital Letter (A-Z) is required.';
            if (!/[a-z]/.test(state.password)) newErrors.password = 'At least one small letter (a-z) is required.';
            if (!/[0-9]/.test(state.password)) newErrors.password = 'At least one number (0-9) is required.';
            if (state.password.length < 8) newErrors.password = 'Minimum 8 letters are required.';
        }
        if (!state.termsAccepted) newErrors.terms = 'You must accept the terms and conditions.';
        return newErrors;
    };

    const validatePassword = useCallback((password) => {
        return validationRules.map(rule => ({
            ...rule,
            isValid: rule.validate(password)
        }));
    }, []);

    const generateOTP = async () => {
        // Call your API to generate OTP
        try {
            const data = await CallApi({
                url: `${baseApiUrl}/api/v1/auth/verification/generate`,
                method: 'POST',
                data: {
                    mobileNumber: state.mobileNumber,
                    useCase: "Signup",
                    userType: "Service Provider"
                }
            });
            if (data.status === 200) {
                toast.success(data.message);
                setOtpGenerated(true);
                return true;
            } else {
                toast.error(data.data.message);
                // setOtpGenerated(true);
                return false;
            }
        } catch (error) {
            toast.error('Something went wrong');
            setOtpGenerated(false);
            return false;
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setState(prevState => ({
                ...prevState,
                errors: newErrors
            }));
        } else {
            // Proceed with form submission
            const response = await generateOTP();
            if (!response) return;
            setModalShow(true);
        }
    };

    const validationStatus = validatePassword(state.password);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value,
            errors: {
                ...prevState.errors,
                [name]: ''
            }
        }));
    };

    const handleLoginClick = (event) => {
        event.preventDefault();
        setIsLoggedIn(true);
    };

    const handleSignUp = () => {
        setIsLoggedIn(false);
    }

    const handlepasswordForgot = () => {
        setIsForgot(true);
    }

    const handleForgotClick = () => {
        setIsForgot(false)
    }

    const handleCheckboxChange = (e) => {
        setState(prevState => ({
            ...prevState,
            termsAccepted: e.target.checked,
            errors: {
                ...prevState.errors,
                terms: ''
            }
        }));
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const buttonParam = params.get('button');
        if (buttonParam === 'login') {
            setIsLoggedIn(true);
        }
    }, []);

    const signIn = () => {
        setState((prevState) => ({
            ...prevState,
            isProfileVerified: false,
        }));
        setIsLoggedIn(true);
        setIsForgot(false);
    }


    return (
        <div>
            <Helmet>
                <title>Connect - Join Our Community</title>
                <meta name="description" content="Create your account with Connect and access a world of convenient services. Sign up now to join our community of service providers and customers." />
                <link rel="canonical" href="https://connectsolutionss.in" />
            </Helmet>
            <ToastContainer />
            <Header profileShown={false} />
            <main className={styles.container}>
                <Modal show={modalShow} isClosable={true} onClose={() => setModalShow(false)}>
                    <OtpVerification
                        isVerifyed={ProfileVerified}
                        generateOTP={generateOTP}
                        mobile={state.mobileNumber}
                        email={state.email}
                        initialOtpGenerated={otpGenerated}
                    />
                </Modal>
                <CustomModal show={termsModal} isClosable={true} onClose={() => setTermsModal(false)} >
                    {policy === 'terms' ? <TermsAndConditions /> : <PrivacyPolicy />}
                </CustomModal>
                {/* Left Section */}
                <CarouselScreen />
                {/* Right Section */}
                {(!state.isProfileVerified && !isLoggedIn) && <div className={styles.formSection}>
                    <form onSubmit={handleSubmit} className={styles.signupForm}>
                        <LazyLoadImage
                            src="/Assets/Modules/SignUp/Logo.svg"
                            alt="Logo..."
                            style={{
                                width: "25px",
                                height: "25px",
                                marginBottom: "8px",
                            }}
                        />
                        <h1>Sign up</h1>
                        <h5 style={{
                            marginBottom: "1rem",
                        }}>Join the connect community!</h5>

                        {/* Full Name */}
                        <label>Full Name<span class={styles.required}>*</span></label>
                        <input
                            type="text"
                            name="fullName"
                            placeholder="Enter your full name"
                            className={`${styles.input} ${state.errors.fullName ? styles.errorInput : ''}`}
                            value={state.fullName}
                            onChange={handleInputChange}
                        />
                        {state.errors.fullName && <p className={styles.errorText}>{state.errors.fullName}</p>}

                        {/* Email Address */}
                        <label>Email Address<span class={styles.required}>*</span></label>
                        <input
                            type="email"
                            name="email"
                            placeholder="Enter your email address"
                            className={`${styles.input} ${state.errors.email ? styles.errorInput : ''}`}
                            value={state.email}
                            onChange={handleInputChange}
                        />
                        {state.errors.email && <p className={styles.errorText}>{state.errors.email}</p>}

                        {/* Phone Number */}
                        <div className={phonsStyle.inputField}>
                            <label htmlFor="phone" className={phonsStyle.label}>
                                Phone Number<span className={phonsStyle.required} aria-hidden="true">*</span>
                            </label>
                            <div className={phonsStyle.phoneInputWrapper}
                                style={{
                                    borderColor: state.errors.mobileNumber ? 'red' : ''
                                }}
                            >
                                <div className={phonsStyle.countryCode}>
                                    <LazyLoadImage
                                        src="/Assets/Modules/ProfileStep/india-flag.svg"
                                        alt=""
                                        className={phonsStyle.countryFlag}
                                        aria-hidden="true"
                                    />
                                    <span className={phonsStyle.countryDialCode}>+91</span>
                                </div>
                                <input
                                    type="text"
                                    id="phone"
                                    name="mobileNumber"
                                    className={phonsStyle.phoneInput}
                                    value={state.mobileNumber}
                                    onChange={handleInputChange}
                                    placeholder="0000000000"
                                    // required
                                    aria-required="true"
                                />
                            </div>
                            {state.errors.mobileNumber && <p className={`${styles.errorText} ${styles.mobileError}`}>{state.errors.mobileNumber}</p>}
                        </div>

                        {/* Password */}
                        <label className={styles.labelStyle}>Password<span class={styles.required}>*</span></label>
                        <section className={loginStyle.passwordStyle}>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                name="password"
                                placeholder="Create a password"
                                value={state.password}
                                onChange={handleInputChange}
                                aria-required="true"
                                className={`${styles.input} ${state.errors.password ? styles.errorInput : ''}`}
                                onFocus={() => setIsPasswordFocused(true)}
                                onBlur={() => setIsPasswordFocused(false)}
                            />
                            {showPassword ? (
                                <LuEye
                                    onClick={() => setShowPassword(!showPassword)}
                                    className={loginStyle.eyeStyle}
                                />
                            ) : (
                                <LuEyeOff
                                    onClick={() => setShowPassword(!showPassword)}
                                    className={loginStyle.eyeStyle}
                                />
                            )}
                        </section>
                        {state.errors.password && <p className={`${styles.errorText} ${styles.mobileError}`}>{state.errors.password}</p>}

                        {isPasswordFocused && <div className={passwordStyle.validationList} role="alert" aria-live="polite">
                            {validationStatus.map((rule) => (
                                <div key={rule.id} className={passwordStyle.validationItem}>
                                    <LazyLoadImage
                                        src={state.password.length === 0 ? rule.info : rule.isValid ? rule.validIcon : rule.inValidIcon}
                                        alt=""
                                        className={passwordStyle.validationIcon}
                                    />
                                    <span className={`${passwordStyle.validationText} ${state.password.length === 0 ? passwordStyle.validationTextIdle : rule.isValid ? passwordStyle.validationSuccess : passwordStyle.validationError
                                        }`}>
                                        {rule.text}
                                    </span>
                                </div>
                            ))}
                        </div>}

                        <div className={styles.terms}>
                            <input type="checkbox" className={styles.check}
                                checked={state.termsAccepted}
                                onChange={handleCheckboxChange}
                            />
                            <label>
                                By clicking, I agree to the {" "}
                                <span onClick={() => {
                                    setTermsModal(true);
                                    setPolicy('terms');
                                }}
                                    className={styles.linkStyle}>terms and conditions</span>
                                {" "} and {" "}
                                <span onClick={() => {
                                    setTermsModal(true);
                                    setPolicy('policy');
                                }}
                                    className={styles.linkStyle}
                                >privacy policy</span>.
                            </label>
                        </div>
                        {state.errors.terms && <p className={`${styles.errorText} ${styles.termserror}`}>{state.errors.terms}</p>}
                        <button type="submit" className={styles.button}>
                            Sign up
                        </button>

                        <p className={styles.footerText}>
                            Already have an account? <a href="#" onClick={handleLoginClick}>Login</a>
                        </p>
                    </form>
                </div>}
                {state.isProfileVerified && <div className={styles.formSection}><Profile signUpdata={state} signIn={signIn} /></div>}
                {(isLoggedIn && !isForgot) && <div className={styles.formSection}><Login isSignedUp={handleSignUp} passwordForgot={handlepasswordForgot} /></div>}
                {isForgot && <div className={styles.formSection}><ForgotPassword isForgotPassword={handleForgotClick} /></div>}
            </main>
        </div>
    );
};

export default SignUp;
