import React, { useState } from 'react';
import styles from './FaqSection.module.css';
import ReactIcon from '../utils/ReactIcon';
import Modal from '../../utils/modal/Modal';
import { QueryModal } from '../utils/QueryModal';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';

export const faqCategories = [
    {
        id: 1,
        icon: "AiFillQuestionCircle",
        title: "General",
        description: "Find quick and easy answers to common queries and learn how our platform and services can make your experience better."
    },
    {
        id: 2,
        icon: "BsPersonCircle",
        title: "Account & Registration",
        description: "Everything you need to know about creating, managing, or troubleshooting your account with ease"
    },
    {
        id: 3,
        icon: "BsFillCalendar2Fill",
        title: "Booking & Cancellation",
        description: "Learn how to book our services, make changes, or cancel requests hassle-free."
    },
    {
        id: 4,
        icon: "MdOutlinePayment",
        title: "Payment Queries",
        description: "Get help with any questions regarding payments, charges, and transaction issues."
    },
    {
        id: 5,
        icon: "HiBriefcase",
        title: "Service Related",
        description: "Find solutions to problems related to service providers during your booking or service experience."
    },
    {
        id: 6,
        icon: "PiUserCircleGearFill",
        title: "Service Partner Queries",
        description: "Checkout answers to some of your common queries on how connect could help our service providers."
    }
];

export const FaqSection = () => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const handleCategoryClick = (id, title) => {
        setSelectedCategory(id === selectedCategory ? null : id);
        navigate('/faq', {
            state: {
                selectedCategory: title,
            }
        })
    };
    // getintouch button function to open modal
    // const handleGetInTouchClick = () => {
    //     setShowModal(true)
    // };

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": faqCategories.map(category => ({
            "@type": "Question",
            "name": category.title,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": category.description
            }
        }))
    };

    return (
        <section id='faq' className={styles.container}>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <div className={styles.content}>
                <header className={styles.header}>
                    <span className={styles.label}>FAQs</span>
                    <h1 className={styles.title}>Got Any Question?</h1>
                    <p className={styles.description}>
                        Don't worry, we've got the answers.
                        <br />
                        Check out the most common queries and make your experience even smoother.
                    </p>
                </header>

                <nav className={styles.cardsContainer} aria-label="FAQ Categories">
                    <ul className={styles.cardsGrid}>
                        {faqCategories.map(category => (
                            <li key={category.id} className={styles.listStyle}>
                                <button
                                    onClick={() => handleCategoryClick(category.id, category.title)}
                                    className={styles.card}
                                    aria-expanded={selectedCategory === category.id}
                                    aria-controls={`category-content-${category.id}`}
                                >
                                    <ReactIcon name={category.icon} size='26px' color='#122577' />
                                    <div className={styles.cardContent} id={`category-content-${category.id}`}>
                                        <h3 className={styles.cardTitle}>{category.title}</h3>
                                        <p className={styles.cardDescription}>{category.description}</p>
                                    </div>
                                </button>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>

            <section className={styles.banner}>
                <div className={styles.bannerContent}>
                    <LazyLoadImage
                        src="/Assets/Modules/Home/FAQ/getintouch.svg"
                        alt="Our support team"
                        className={styles.bannerImage}
                    />
                    <div className={styles.bannerText}>
                        <h2 className={styles.bannerTitle}>Still have questions?</h2>
                        <p className={styles.bannerDescription}>
                            Can't find the answer you're looking for? Please Connect to our friendly team.
                        </p>
                    </div>
                    {/* <button
                        onClick={handleGetInTouchClick}
                        className={styles.getInTouchButton}
                        aria-label="Get in touch with our support team"
                    >
                        Get in touch
                    </button> */}
                </div>
            </section>
            <Modal show={showModal} isClosable={true} onClose={() => setShowModal(false)}>
                <QueryModal onClose={() => setShowModal(false)} />
            </Modal>
        </section>
    );
};