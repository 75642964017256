export const servicesData = {
    construction: {
        image: '/Assets/Modules/Home/Services/Construction/default.svg',
        title: 'Construction Sector',
        description: 'Cementing Dreams, Crafting Futures! From towering skyscrapers to cozy abodes, from new builds to renovations, our success is built on the dedication of our commitment to excellence by offering skilled labor, expert contractors, innovative engineers, creative interior designers, and visionary architects. We strive to provide quality, reliability, and transparency with our comprehensive construction services ensuring high-quality results for you.',
        categories: [
            {
                id: 'cat1',
                name: 'Professionals',
                professionals: [
                    { id: 'prof1', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Professional Architects' },
                    { id: 'prof2', icon: '/Assets/Modules/Home/Services/Construction/Icons/interior.svg', title: 'Interior Designer' },
                    { id: 'prof3', icon: '/Assets/Modules/Home/Services/Construction/Icons/civil.svg', title: 'Civil Engineers' },
                    { id: 'prof4', icon: '/Assets/Modules/Home/Services/Construction/Icons/mechanical.svg', title: 'Mechanical Engineers' },
                    { id: 'prof5', icon: '/Assets/Modules/Home/Services/Construction/Icons/gear.svg', title: 'Electrical Engineers' },
                    { id: 'prof6', icon: '/Assets/Modules/Home/Services/Construction/Icons/environment.svg', title: 'Enviromental Architects' },
                    { id: 'prof7', icon: '/Assets/Modules/Home/Services/Construction/Icons/project.svg', title: 'Project Managers' },
                    { id: 'prof8', icon: '/Assets/Modules/Home/Services/Construction/Icons/surveyor.svg', title: 'Surveyors' },
                    { id: 'prof9', icon: '/Assets/Modules/Home/Services/Construction/Icons/consultant.svg', title: 'Consultants' },
                ]
            },
            {
                id: 'cat2',
                name: 'Labours',
                professionals: [
                    { id: 'lab_prof1', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Carpenter' },
                    { id: 'lab_prof2', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Welder' },
                    { id: 'lab_prof3', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Electrician' },
                    { id: 'lab_prof4', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Floor Installer' },
                    { id: 'lab_prof5', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Masons' },
                    { id: 'lab_prof6', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Plumbers' },
                    { id: 'lab_prof7', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Painters' },
                    { id: 'lab_prof8', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Steelworkers' },
                    { id: 'lab_prof9', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Roofers' },
                    { id: 'lab_prof10', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Plasterers' },
                    { id: 'lab_prof11', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Glaziers' },
                    { id: 'lab_prof12', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'HVAC Technicians' },
                    { id: 'lab_prof13', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Water proofing' },
                    { id: 'lab_prof14', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Paver Installers' },
                    { id: 'lab_prof15', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Scaffolders' },
                    { id: 'lab_prof16', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Tilers' },
                    { id: 'lab_prof17', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Landscaping' },
                    { id: 'lab_prof18', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Construction Helper' },
                    { id: 'lab_prof19', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'General' },
                    { id: 'lab_prof20', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Warehouse Workers' },
                    { id: 'lab_prof21', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Transport workers' },
                    { id: 'lab_prof22', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Safety Officers' },
                    { id: 'lab_prof23', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Heavy Equip. Operators' },
                    { id: 'lab_prof24', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Machine Operators' },
                ]
            },
            {
                id: 'cat3',
                name: 'Contractors',
                professionals: [
                    { id: 'con_prof5', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'General' },
                    { id: 'con_prof6', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Sub Contractors' },
                ]
            },
        ],
    },
    events: {
        image: '/Assets/Modules/Home/Services/Events/default.svg',
        title: 'Events Sector',
        description: 'At Connect Solutionss, we believe every event deserves to be unforgettable. Whether it’s a lavish wedding, a corporate seminar, or a fun-filled birthday bash, we’re here to take the stress off your shoulders. Our all-in-one platform connects you with trusted vendors, experienced planners, and creative ideas to make your event truly special. Every event is a chance to create lasting memories. Whether it’s a wedding, corporate seminar, milestone celebration, business gathering, or personal get-together. We’re here to make it extraordinary. You can even design your own events, connecting with trusted vendors and planners to ensure every moment is special.',
        categories: [
            {
                id: 'cat3', name: 'Vendors',
                professionals: [
                    { id: 'prof3', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Catering Services' },
                    { id: 'prof4', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Drone' },
                    { id: 'prof5', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Lights' },
                    { id: 'prof6', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Fireworks' },
                    { id: 'prof7', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'DJ' },
                    { id: 'prof8', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Sheesha' },
                    { id: 'prof9', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Florals' },
                    { id: 'prof10', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Cleaning Services' },
                ],
            },
            {
                id: 'cat4', name: 'Professionals',
                professionals: [
                    { id: 'prof3', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Singers/Musicians' },
                    { id: 'prof4', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Magicians' },
                    { id: 'prof5', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Photo-graphers' },
                    { id: 'prof6', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Mixologist' },
                    { id: 'prof7', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Dance Groups' },
                ],
            },
            {
                id: 'cat5', name: 'Create Event',
                professionals: [
                    { id: 'prof3', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Physical Event' },
                    { id: 'prof4', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Virtual Event' },
                ],
            },
        ],
    },
    healthcare: {
        image: '/Assets/Modules/Home/Services/Healthcare/default.svg',
        title: 'Healthcare Sector',
        description: `At Connect, we bring healthcare to your doorstep. Whether it’s home care for your loved ones, a clinic visit for expert advice, or a teleconsultation when you're unable to step out, we make sure you never miss out on quality care. Our blood donation services ensure you're never alone when help is needed. With us, healthcare feels personal, accessible, and always there when you need it most.`,
        categories: [
            {
                id: 'cat3', name: 'Home Care',
                professionals: [
                    { id: 'prof3', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Care Giver' },
                    { id: 'prof4', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Nurses' },
                ],
            },
            {
                id: 'cat4', name: 'Donate Blood',
                professionals: [
                    { id: 'prof3', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Emergencies' },
                    { id: 'prof4', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Every 6 Months' },
                    { id: 'prof5', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'On Occassion' },
                ],
            },
            {
                id: 'cat5', name: 'Request Blood',
                professionals: [
                    { id: 'prof3', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'For Self' },
                    { id: 'prof4', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'For Others' },
                ],
            },
        ],
    },
    homeservices: {
        image: '/Assets/Modules/Home/Services/HomeServices/default.svg',
        title: 'Home Services Sector',
        description: 'Your home deserves the best, and we deliver it right to your doorstep. From quick repairs to complete makeovers, our experts handle it all with care and precision. Experience seamless convenience and reliable service tailored to your needs. Let us make your home a place you love to live in!',
        categories: [
            {
                id: 'cat3', name: 'Personal',
                professionals: [
                    { id: 'prof3', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Ayurvedic Treatment' },
                    { id: 'prof4', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Freestyle Dance Tutor' },
                ],
            },
            {
                id: 'cat4', name: 'Speciality Services',
                professionals: [
                    { id: 'prof3', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Cook' },
                    { id: 'prof4', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Tailor' },
                    { id: 'prof5', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Maids' },
                    { id: 'prof6', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Laundry' },
                ],
            },
            {
                id: 'cat5', name: 'Home Services',
                professionals: [
                    { id: 'prof3', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Carpenter' },
                    { id: 'prof4', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Cleaning Specialist' },
                    { id: 'prof5', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Electricians' },
                    { id: 'prof6', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Gardener' },
                    { id: 'prof7', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Pest Control' },
                    { id: 'prof8', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Plumbers' },
                ],
            },
        ]
    },
    petcare: {
        image: '/Assets/Modules/Home/Services/Petcare/default.svg',
        title: 'Pet Care Sector',
        description: `Your furry friends deserve nothing but the finest care. From grooming to health checkups, we bring expert services to your doorstep with love and dedication. Tailored for every paw, we ensure their happiness and well-being. Trust us to treat your pets like family!
        Your pets are family, and they deserve the best care. From grooming and walking to tracking their safety and even helping them find a friend with pet dating, we’re here to ensure their happiness and well-being every step of the way`,
        categories: [
            {
                id: 'cat3', name: 'Grooming',
                professionals: [
                    { id: 'prof3', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Nail Trimming' },
                    { id: 'prof4', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Dental Care' },
                    { id: 'prof5', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Eye Cleaning' },
                    { id: 'prof6', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Bathing' },
                    { id: 'prof7', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Paw massage' },
                ],
            },
            {
                id: 'cat4', name: 'Walking',
                professionals: [
                    { id: 'prof3', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Pet Walker' },
                    { id: 'prof4', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Self' },
                ],
            },
            {
                id: 'cat5', name: 'Training',
                professionals: [
                    { id: 'prof3', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Behavioural' },
                    { id: 'prof4', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Potty' },
                    { id: 'prof5', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Obedience' },
                    { id: 'prof6', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Agility' },
                    { id: 'prof7', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Therapy' },
                ],
            },
            {
                id: 'cat6', name: 'Dating/Mating',
                professionals: [
                    { id: 'prof3', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Dating/Mating' },
                    { id: 'prof4', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Pet Listing' },
                ],
            },
            {
                id: 'cat7', name: 'Adoption',
                professionals: [
                    { id: 'prof3', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Adopt a Pet' },
                    { id: 'prof4', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'List for Adoption' },
                ],
            },
        ],
    },
    entertainment: {
        image: '/Assets/Modules/Home/Services/Entertainment/default.svg',
        title: 'Entertainment Sector',
        description: `Every dream has a spark, and every talent deserves the chance to shine. For actors, writers, models, and influencers, this is where passion meets opportunity. We’re here to turn your dreams into reality, giving your story the spotlight it deserves to inspire the world.`,
        categories: [
            {
                id: 'cat3', name: 'Makers',
                professionals: [
                    { id: 'prof3', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Costume Designers' },
                    { id: 'prof4', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Cameraman ' },
                    { id: 'prof5', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Casting' },
                    { id: 'prof6', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Action Master' },
                ],
            },
            {
                id: 'cat4', name: 'Performers',
                professionals: [
                    { id: 'prof3', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Male Artist' },
                    { id: 'prof4', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Female Artist' },
                    { id: 'prof5', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Dance Groups' },
                    { id: 'prof6', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Singers' },
                    { id: 'prof7', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Crowd' },
                ],
            },
            {
                id: 'cat5', name: 'Vendors',
                professionals: [
                    { id: 'prof3', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Vanity' },
                    { id: 'prof4', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Light' },
                    { id: 'prof5', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Electricians' },
                    { id: 'prof6', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Hair Dresser' },
                    { id: 'prof7', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Tailor' },
                    { id: 'prof8', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Bungalow' },
                    { id: 'prof9', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Cleaning' },
                    { id: 'prof10', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Cameras' },
                    { id: 'prof11', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Panther' },
                    { id: 'prof12', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Caterers' },
                ],
            },
            {
                id: 'cat6', name: 'Influencers',
                professionals: [
                    { id: 'prof3', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Male Influencer' },
                    { id: 'prof4', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Female Influencer' },
                ],
            },
            {
                id: 'cat7', name: 'Support Crew',
                professionals: [
                    { id: 'prof3', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Spot Boy' },
                    { id: 'prof4', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Light Boy' },
                    { id: 'prof5', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Fashion Designer' },
                    { id: 'prof6', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Makeup Artist' },
                    { id: 'prof7', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Set Designer' },
                    { id: 'prof8', icon: '/Assets/Modules/Home/Services/Construction/Icons/architect.svg', title: 'Helpers' },
                ],
            },
        ],
    },
};