import { useCallback, useState } from "react";
import styles from "./Login.module.css";
// import { FcGoogle } from "react-icons/fc";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { Link } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Login = ({ setForgetPasswordActive }) => {
  const [isPassVis, setIsPassVis] = useState(false);
  // const [mobileNumber, setMobileNumber] = useState("");
  // const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const [state, setState] = useState({
    isPassVis: false,
    verificationStage: 0,
    mobileNumber: "",
    password: "",
    isPasswordValid: false,
    isMobileNumberVerified: false,
    isTnCAccepted: -1,
    errors: {
      mobileNumber: "",
      otpFilled: false,
      otpVerified: false,
      password: "",
      rePassword: "",
    },
  });

  const togglePassVisibility = () => {
    setIsPassVis(!isPassVis);
  };

  const baseApiUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_DEV
      : process.env.REACT_APP_API_PROD;
  const dashboardBaseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DASHBOARD_URL_DEV
      : process.env.REACT_APP_DASHBOARD_URL_PROD;

  console.log(dashboardBaseUrl);

  const onMobileChange = useCallback((e) => {
    const num = e.target.value;
    const mobileRegex = /^[6789]\d{9}$/;
    setState((prevState) => ({
      ...prevState,
      mobileNumber: num,
      isMobileNumberVerified: mobileRegex.test(num),
    }));
  }, []);

  const onPasswordChange = useCallback((e) => {
    const pass = e.target.value;
    const passRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?]).{8,20}$/;
    setState((prevState) => ({
      ...prevState,
      password: pass,
      isPasswordValid: passRegex.test(pass),
    }));
    // setPassword(pass);
  }, []);

  const loginHandler = async () => {
    try {
      const res1 = await axios.post(
        `${baseApiUrl}/api/v1/auth/login`,
        {
          mobileNumber: state.mobileNumber,
          password: state.password,
          rememberMe,
        },
        {
          withCredentials: true,
        }
      );

      const accessToken = res1.data.data.accessToken;
      console.log("You are logged in:", res1);
      toast.success(res1.data.message);

      console.log(accessToken);
      const res2 = await axios.post(
        `${baseApiUrl}/api/v1/auth/generate-login-token`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          withCredentials: true,
        }
      );

      console.log(res2);
      window.location.href = `${dashboardBaseUrl}`;
    } catch (error) {
      console.error("Error logging in:", error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className={styles.cont}>
      <ToastContainer />
      <section className={styles.top}>
        <img
          className={styles.top__img}
          src={`/Assets/Authentication/logo.svg`}
          alt="logo"
        />
        <text className={styles.top__head}>Hi User! Welcome Back</text>
      </section>
      <section className={styles.middle}>
        {/* <section className={styles.middle__continueWithGoogle}>
                    <FcGoogle className={styles.middle__continueWithGoogle__icon} />
                    <h3>Continue with Google</h3>
                </section> */}
        <section className={styles.middle__divider}>
          <section className={styles.middle__divider__line} />
          {/* <section className={styles.middle__divider__content}>
                        or
                    </section> */}
        </section>

        <section className={styles.middle__entries}>
          <section className={styles.middle__entries__field}>
            <section className={styles.middle__entries__field__head}>
              Mobile number
            </section>
            <input
              type="number"
              placeholder="Enter your mobile number"
              value={state.mobileNumber}
              onChange={onMobileChange}
            />
            {!state.isMobileNumberVerified &&
              state.mobileNumber.length !== 0 && (
                <div className={styles.error}>Invalid mobile number!</div>
              )}
          </section>
          <section className={styles.middle__entries__field}>
            <section className={styles.middle__entries__field__head}>
              Password
            </section>
            <section className={styles.middle__entries__field__cover}>
              <input
                type={isPassVis ? "text" : "password"}
                placeholder="Enter your password"
                value={state.password}
                onChange={onPasswordChange}
              />
              {isPassVis ? (
                <LuEye
                  onClick={togglePassVisibility}
                  className={styles.middle__entries__field__eyeIcon}
                />
              ) : (
                <LuEyeOff
                  onClick={togglePassVisibility}
                  className={styles.middle__entries__field__eyeIcon}
                />
              )}
            </section>
            {!state.isPasswordValid && state.password.length !== 0 && (
              <div className={styles.error}>
                Your password must contain at least one uppercase letter, one
                lowercase letter, one digit, one special character, and be 8-20
                characters long!
              </div>
            )}
          </section>
          <section className={styles.middle__entries__rememberMe}>
            <section className={styles.middle__entries__rememberMe__left}>
              <input
                type="checkbox"
                onChange={() => setRememberMe(!rememberMe)}
              />
              <label>Remember me</label>
            </section>
            <text
              onClick={() => {
                setForgetPasswordActive(true);
              }}
              className={styles.middle__entries__rememberMe__right}
            >
              Forgot Password?
            </text>
          </section>
        </section>
      </section>
      <section className={styles.bottom}>
        <button
          disabled={!state.isMobileNumberVerified || !state.isPasswordValid}
          className={`${styles.bottomButton} ${
            !state.isMobileNumberVerified || !state.isPasswordValid
              ? styles.disabledBtn
              : ""
          }`}
          onClick={loginHandler}
        >
          Log in
        </button>
        <section className={styles.bottom__signUp}>
          <section className={styles.bottom__signUp__left}>
            Don't have an account yet?
          </section>
          <section className={styles.bottom__signUp__right}>
            <Link to={"/auth/signup-step-1"}>Sign up</Link>
          </section>
        </section>
      </section>
    </div>
  );
};

export default Login;
