import styles from "../styles.module.css";

const TermOfUse = ({hide=false}) => {
    return (

        <div className={styles.child}>
            {!hide && <h2>Refund Policy</h2>}
            <p>
                At <b>Connect Solutions</b>, we prioritize customer satisfaction and
                aim to provide high-quality services through our platform. However, we
                understand that situations may arise where refunds are necessary.
                Below is our refund policy:
            </p>
            <p className={styles.heading}>1. Eligibility and Registration</p>
            <ul className={styles.subList}>
                <li>
                    Refunds will only be considered in cases where services were not
                    delivered as promised or if there was a significant discrepancy in
                    the service provided.
                </li>
                <li>
                    Requests for refunds must be made within <b>7 days</b> of the
                    service date.
                </li>
            </ul>
            <p className={styles.heading}>2. Non-Refundable Services:</p>
            <ul className={styles.subList}>
                <li>
                    Services that have been fully delivered and accepted by the
                    customer.
                </li>
                <li>
                    Cancellations made after the service has been completed or in
                    progress are not eligible for refunds.
                </li>
            </ul>
            <p className={styles.heading}>3. How to Request a Refund:</p>
            <ul className={styles.subList}>
                <li>
                    Submit your refund request through our app or by contacting our
                    customer support at <b>Support@connectsolutionss.in</b>.
                </li>
                <li>
                    Please provide a detailed explanation of the issue and any relevant
                    evidence (e.g., screenshots, receipts).
                </li>
            </ul>
            <p className={styles.heading}>4. Refund Process:</p>
            <ul className={styles.subList}>
                <li>
                    Once your request is received and reviewed, we will notify you of
                    the approval or rejection of your refund.
                </li>
                <li>
                    Approved refunds will be processed within <b>5-7 Working days</b>{" "}
                    and will be credited back to your original payment method.
                </li>
            </ul>
            <p className={styles.heading}>5. Partial Refunds:</p>
            <ul className={styles.subList}>
                <li>
                    In certain cases, partial refunds may be granted if part of the
                    service was satisfactory but did not fully meet expectations.
                </li>
            </ul>
            <p className={styles.heading}>6. Dispute Resolution:</p>
            <ul className={styles.subList}>
                <li>
                    If there is a disagreement about the refund decision, you can appeal
                    by contacting our support team. We will investigate the case
                    thoroughly and provide a final resolution.
                </li>
            </ul>
            <p>
                For any further questions or concerns regarding our refund policy,
                feel free to reach out to our support team at <b>Support@connectsolutionss.in.</b>
            </p>
        </div>
    );
};

export default TermOfUse;
