import React from "react";

// Dynamically import all libraries from `react-icons`
const iconLibraries = {
    fa: require("react-icons/fa"), // FontAwesome
    md: require("react-icons/md"), // Material Design
    ai: require("react-icons/ai"), // Ant Design
    bs: require("react-icons/bs"), // Bootstrap
    ti: require("react-icons/ti"), // Typicons
    hi: require("react-icons/hi"), // Heroicons
    bi: require("react-icons/bi"), // BoxIcons
    io: require("react-icons/io"), // IonIcons
    ri: require("react-icons/ri"), // Remix Icons
    cg: require("react-icons/cg"), // CSS.gg
    fi: require("react-icons/fi"), // Feather
    gi: require("react-icons/gi"), // Game Icons
    im: require("react-icons/im"), // IcoMoon
    si: require("react-icons/si"), // Simple Icons
    sl: require("react-icons/sl"), // Simple Line Icons
    tb: require("react-icons/tb"), // Tabler Icons
    vsc: require("react-icons/vsc"), // VSCode Icons
    pi: require("react-icons/pi"), // Payment Icons
    io5: require("react-icons/io5"), // IonIcons v5
    ci: require("react-icons/ci"),
};

const FallbackIcon = ({ size = "24px", color = "red", ...props }) => (
    <span
        style={{ fontSize: size, color, ...props.style }}
        className={props.className}
    >
        ⚠️
    </span>
);

const ReactIcon = ({
    name = "FaBeer", // Default icon
    size = "24px",
    color = "black",
    className = "",
    style = {},
    ...props // Other dynamic props
}) => {
    // Infer the library from the prefix of the icon name
    let prefix = name.slice(0, 2).toLowerCase();
    let IconLibrary = iconLibraries[prefix];

    // Special case for IonIcons 5
    if (prefix === "io" && iconLibraries.io5[name]) {
        prefix = "io5";
        IconLibrary = iconLibraries[prefix];
    }

    if (!IconLibrary) {
        console.error(`Library with prefix "${prefix}" not found. Check your icon name.`);
        return <FallbackIcon size={size} color={color} className={className} style={style} {...props} />;
    }

    // Resolve the icon component dynamically
    const IconComponent = IconLibrary[name];

    if (!IconComponent) {
        console.error(`Icon "${name}" not found in library with prefix "${prefix}".`);
        return <FallbackIcon size={size} color={color} className={className} style={style} {...props} />;
    }

    // Render the icon
    return (
        <IconComponent
            size={size}
            color={color}
            className={className}
            style={style}
            {...props}
        />
    );
};

export default ReactIcon;
