import React, { useEffect, useRef, useState } from 'react';
import styles from './Services.module.css';
import { servicesData } from './Services/servicedata';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactIcon from '../utils/ReactIcon';
import { Helmet } from 'react-helmet';

export const Services = () => {
    const [activeService, setActiveService] = useState('construction');
    const [activeCategory, setActiveCategory] = useState(servicesData.construction.categories[0].id);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [scrollIndex, setScrollIndex] = useState(0);
    const professionalsRef = useRef(null);

    const handleServiceClick = (serviceId) => {
        setActiveService(serviceId);
        setActiveCategory(servicesData[serviceId].categories[0].id);
    };

    const handleCategoryClick = (categoryId) => {
        setActiveCategory(categoryId);
    };

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - professionalsRef.current.offsetLeft);
        setScrollLeft(professionalsRef.current.scrollLeft);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - professionalsRef.current.offsetLeft;
        const walk = (x - startX);
        professionalsRef.current.scrollLeft = scrollLeft - walk;
    };

    const scrollProfessionals = (direction) => {
        if (direction === 'left' && scrollIndex > 0) {
            setScrollIndex(prev => prev - 1);
        } else if (direction === 'right' && scrollIndex < activeCategoryData.professionals.length - 5) {
            setScrollIndex(prev => prev + 1);
        }
    };

    const handleButtonPress = (direction) => {
        const scrollAmount = direction === 'left' ? -400 : 400; // Adjust this value as needed
        if (professionalsRef.current) {
            professionalsRef.current.scrollBy({
                left: scrollAmount,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        if (professionalsRef.current) {
            professionalsRef.current.style.transform = `translateX(-${scrollIndex * 20}%)`;
        }
    }, [scrollIndex]);

    useEffect(() => {
        setScrollIndex(0);
    }, [activeCategory]);

    const activeServiceData = servicesData[activeService];
    const activeCategoryData = activeServiceData.categories.find(cat => cat.id === activeCategory);

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": activeServiceData.title,
        "description": activeServiceData.description,
        "provider": {
            "@type": "Organization",
            "name": "CONNECT"
        },
        "serviceType": activeCategoryData.name
    };

    return (
        <section id='services' className={styles.servicesSection}>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <div className={styles.contentWrapper}>
                <header className={styles.headerContent}>
                    <h1 className={styles.mainTitle}>
                        Experience the range of diverse services provided by CONNECT
                    </h1>
                    <p className={styles.subtitle}>
                        <strong className={styles.subtitleHighlight}>
                            Having trouble with finding your need?
                        </strong>{' '}
                        <span className={styles.subtitleSecondary}>
                            Please leave us your email and we shall have it for you.
                        </span>
                    </p>
                </header>

                <nav className={styles.tabsContainer} aria-label="Service categories">
                    {Object.keys(servicesData).map(serviceId => (
                        <button
                            key={serviceId}
                            className={`${styles.tab} ${serviceId === activeService ? styles.active : ''}`}
                            onClick={() => handleServiceClick(serviceId)}
                            aria-pressed={serviceId === activeService}
                        >
                            {servicesData[serviceId].title}
                        </button>
                    ))}
                </nav>

                <main className={styles.contentArea}>
                    <article className={styles.serviceInfo}>
                        <LazyLoadImage
                            src={activeServiceData.image}
                            alt={activeServiceData.title}
                            className={styles.serviceImage}
                        />

                        <div className={styles.serviceDetails}>
                            <h2 className={styles.sectorTitle}>{activeServiceData.title}</h2>
                            <p className={styles.description}>
                                {activeServiceData.description}
                            </p>

                            <nav className={styles.categoryTabs} aria-label="Professional categories">
                                {activeServiceData.categories.map(category => (
                                    <button
                                        key={category.id}
                                        className={`${styles.categoryTab} ${category.id === activeCategory ? styles.active : ''}`}
                                        onClick={() => handleCategoryClick(category.id)}
                                        aria-pressed={category.id === activeCategory}
                                    >
                                        {category.name}
                                    </button>
                                ))}
                            </nav>

                            <section className={styles.professionalsContainer}>
                                <ul className={styles.professionalsGrid} ref={professionalsRef}
                                    onMouseDown={handleMouseDown}
                                    onMouseLeave={handleMouseLeave}
                                    onMouseUp={handleMouseUp}
                                    onMouseMove={handleMouseMove}
                                >
                                    {activeCategoryData.professionals.map(professional => (
                                        <li key={professional.id} className={styles.professionalCard}>
                                            <LazyLoadImage
                                                src={professional.icon}
                                                alt={professional.title}
                                                className={styles.cardIcon}
                                            />
                                            <h3 className={styles.cardTitle}>
                                                {professional.title.split(' ').map((word, index, array) => (
                                                    <React.Fragment key={index}>
                                                        {word}
                                                        {index < array.length - 1 && <br />}
                                                    </React.Fragment>
                                                ))}
                                            </h3>
                                        </li>
                                    ))}
                                </ul>

                                <footer className={styles.navigationControls}>
                                    <span className={styles.showingCount}>
                                        Showing {Math.min(5, activeCategoryData.professionals.length - scrollIndex)}/
                                        {activeCategoryData.professionals.length}
                                    </span>
                                    <nav className={styles.navigationButtons}>
                                        <button
                                            className={styles.navButton}
                                            // onClick={() => scrollProfessionals('left')}
                                            // disabled={scrollIndex === 0}
                                            onClick={() => handleButtonPress('left')}
                                            aria-label="Scroll left"
                                        >
                                            <ReactIcon
                                                name="FaCaretLeft"
                                                size="20px"
                                                color="#000"
                                            />
                                        </button>
                                        <button
                                            className={styles.navButton}
                                            // onClick={() => scrollProfessionals('right')}
                                            // disabled={scrollIndex >= activeCategoryData.professionals.length - 5}
                                            onClick={() => handleButtonPress('right')}
                                            aria-label="Scroll right"
                                        >
                                            <ReactIcon
                                                name="FaCaretRight"
                                                size="20px"
                                                color="#000"
                                            />
                                        </button>
                                    </nav>
                                </footer>
                            </section>
                        </div>
                    </article>
                </main>
            </div>
        </section>
    );
}