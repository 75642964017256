import React, { useState } from 'react';
import styles from './Footer.module.css';
import ReactIcon from '../utils/ReactIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import { navItems } from './Header';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';

const legalDataSet = [
// {
//     name: 'Terms & Conditions',
//     link: 'terms'
// },
{
    name: 'Privacy Policy',
    link: 'privacy'
},
{
    name: 'Return & Refunds',
    link: 'refund'
}
]

export const Footer = () => {
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (email) {
            console.log('Subscribing email:', email);
            setEmail('');
        }
    };

    const handleBackToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const navigate = useNavigate();
    const location = useLocation();

    const handleNavItemClick = (link) => {
        if (link === '#support') {
            const supportSection = document.getElementById('support');
            if (supportSection) {
                supportSection.scrollIntoView({ behavior: 'smooth' });
            } else {
                navigate('/');
            }
        } else if (link === '#services') {
            const servicesSection = document.getElementById('services');
            if (servicesSection) {
                servicesSection.scrollIntoView({ behavior: 'smooth' });
            } else {
                navigate('/');
            }
        } else if (link === '#contact') {
            const contactSection = document.getElementById('footer');
            if (contactSection) {
                contactSection.scrollIntoView({ behavior: 'smooth' });
            } else {
                navigate('/');
            }
        } else if (link === '/') {
            const homeSection = document.getElementById('home');
            if (homeSection) {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            } else {
                navigate('/');
            }
        }
        else {
            navigate(link);
        }
    };

    const handleClick = (e, path) => {
        e.preventDefault();
        const absolutePath = path.startsWith('/') ? path : `/${path}`;

        if (location.pathname !== absolutePath) {
            navigate(absolutePath);
        }
    };

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "CONNECT Solutions",
        "url": "https://connectsolutionss.in",
        "logo": "/Assets/Modules/Home/connectLogo.svg",
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91-7738388034",
            "contactType": "customer service"
        },
        "sameAs": [
            "https://www.facebook.com/profile.php?id=61560584010523",
            "https://www.instagram.com/connect_solutionss",
            "https://www.linkedin.com/company/connectsolutionss/",
            "https://x.com/connect_solutns"
        ]
    };

    return (
        <footer id="footer" className={styles.footer} role="contentinfo">
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <div className={styles.gradientBg} aria-hidden="true" />
            <div className={styles.topSection}>
                <div className={styles.contentGrid}>
                    <div className={styles.logoColumn}>
                        <LazyLoadImage
                            src="/Assets/Modules/Home/connectLogo.svg"
                            alt="CONNECT Solutions logo"
                            className={styles.mainLogo}
                        />
                        <LazyLoadImage
                            src="/Assets/Modules/Home/qrSvg.svg"
                            alt="QR code for CONNECT Solutions"
                            className={styles.secondaryLogo}
                        />
                    </div>

                    <nav className={styles.menuColumn} aria-label="Footer navigation">
                        <div className={styles.menuContainer}>
                            <div className={styles.menuSection}>
                                <h2 className={styles.menuTitle}>Menu</h2>
                                <ul className={styles.menuList}>
                                    {navItems.map((item, index) => (
                                        <li key={item.text} className={styles.menuItem}>
                                            <div
                                                key={index}
                                                tabIndex="0"
                                                role="button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleNavItemClick(item.link)
                                                }}
                                            >
                                                {item.text}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <section className={styles.menuSection}>
                                <h2 className={styles.menuTitle}>Legal</h2>
                                <ul className={styles.menuList}>
                                    {legalDataSet.map((item, index) => (
                                        <li key={item.name} className={styles.menuItem}>
                                            <div
                                                key={index}
                                                tabIndex='0'
                                                role='button'
                                                onClick={(e) => handleClick(e, item.link)}
                                            >
                                                {item.name}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </section>
                        </div>
                    </nav>

                    <div className={styles.contactColumn}>
                        <button
                            className={styles.callRequestButton}
                            onClick={() => console.log('Request call clicked')}
                        >
                            Request a call
                        </button>
                        <div className={styles.contactInfo}>
                            <a href="tel:+91 7738388034" className={styles.phoneNumber}>
                                +91 - 7738388034
                            </a>
                            <a href="mailto:Support@connectsolutionss.in" className={styles.email}>
                                Support@connectsolutionss.in
                            </a>
                        </div>
                    </div>
                </div>

                <div className={styles.bottomRow}>
                    <nav className={styles.socialSection}>
                        {[
                            { icon: 'TiSocialFacebook', alt: 'Facebook', url: 'https://www.facebook.com/profile.php?id=61560584010523&mibextid=LQQJ4d' },
                            { icon: 'BiLogoInstagramAlt', alt: 'Instagram', url: 'https://www.instagram.com/connect_solutionss?igsh=bTZ1YXNpc2V6c2Vh&utm_source=qr' },
                            { icon: 'FaLinkedin', alt: 'Linkedin', url: 'https://www.linkedin.com/company/connectsolutionss/' },
                            { icon: 'BsTwitterX', alt: 'Twitter', url: 'https://x.com/connect_solutns?s=21&t=xeqBgjMxSin4lFC3XGTAKg' },
                        ].map((social, index) => (
                            <a
                                key={index}
                                href={social.url}
                                className={styles.socialLink}
                                aria-label={`Visit our ${social.alt} page`}
                            >
                                <ReactIcon name={social.icon} size='24px' color='#fff' />
                            </a>
                        ))}
                    </nav>

                    <address className={styles.addressText}>
                        Jethabhai Building DR Atmaram merchant Road, Bhuleshwar Mumbai Mumbai City MH 400002 India
                    </address>

                    <div className={styles.backToTopSection}>
                        <button
                            onClick={handleBackToTop}
                            className={styles.backToTopButton}
                            aria-label="Scroll back to top"
                        >
                            Back to top
                            <ReactIcon name='IoArrowUpCircleOutline' size="44px" />
                        </button>
                    </div>
                </div>
            </div>

            <section className={styles.subscriptionSection}>
                <div className={styles.subscriptionContent}>
                    <h2 className={styles.newsletterTitle}>Join our newsletter!</h2>
                    <p className={styles.newsletterDescription}>
                        Get the news of latest offers and deals! We won't spam you
                    </p>
                </div>

                <form onSubmit={handleSubmit} className={styles.subscriptionForm}>
                    <h6>Enter your email to receive offers</h6>
                    <div className={styles.inputWrapper}>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="example@gmail.com"
                            className={styles.emailInput}
                            aria-label="Email subscription"
                            required
                        />
                        <button type="submit" className={styles.subscribeButton}>
                            Subscribe
                        </button>
                    </div>
                </form>
            </section>

            <div className={styles.copyrightSection}>
                <span>© {new Date().getFullYear()} — Copyright</span>
                <span>All rights reserved</span>
            </div>
        </footer>
    );
};