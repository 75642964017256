import React from 'react';
import styles from './AppSection.module.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';

export const AppSection = () => {
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "MobileApplication",
        "name": "CONNECT Mobile App",
        "operatingSystem": "Android",
        "applicationCategory": "UtilityApplication",
    };
    return (
        <section id='app' className={styles.appSection}>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <div className={styles.content}>
                <header>
                    <h1 className={styles.title}>MOBILE APP</h1>
                    <p className={styles.subtitle}>Download Our App </p>
                </header>
                <main>
                    <p className={styles.description}>Available on Google Play  Store!</p>
                    <LazyLoadImage
                        src="/Assets/Modules/Home/playstoreQR.jpeg"
                        alt="QR code to download CONNECT app"
                        className={styles.svgImage} />
                    <nav>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.connectsolutionss&pcampaignid=web_share"
                            className={styles.playstoreLink}
                            aria-label="Download CONNECT app from Google Play Store"
                        >
                            <LazyLoadImage
                                src="/Assets/Modules/Home/playstore.svg"
                                alt="Google Play Store logo"
                                className={styles.playstoreIcon}
                            />
                        </a>
                    </nav>
                </main>
            </div>
            <div className={styles.backgroundImage}></div>
        </section>
    );
};