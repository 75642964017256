import React, {  useState } from 'react'
import styles from './Profile.module.css';
import { SelectionOption } from '../utils/SelectionOption';
import { PersonalDetails } from '../ProfileStep/PersonalDetails';
import { AddressDetails } from '../ProfileStep/AddressDetails';
import { IdentityDocuments } from '../ProfileStep/IdentityDocument';
import SelectionForm from '../ProfileStep/SelectionForm';
import { useNavigate } from 'react-router-dom';
import ReactIcon from '../utils/ReactIcon';
import Modal from '../../utils/modal/Modal';
import SignupSuccess from '../ProfileStep/SignupSuccess';
import CallApi from '../Api/Callapi';
import { toast, ToastContainer } from 'react-toastify';

const options = [
    {
        id: 'Individual',
        title: 'I am an Individual',
        description: 'Lorem ipsum is a dummy or placeholder text commonly used in graphic design,'
    },
    {
        id: 'Business',
        title: 'I am a Business',
        description: 'Lorem ipsum is a dummy or placeholder text commonly used in graphic design,'
    }
];

export default function Profile({ signUpdata, signIn }) {
    const [selectedOption, setSelectedOption] = useState('Individual');
    const [stepCount, setStepCount] = useState(1);
    // const [isValid, setIsValid] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [profileData, setProfileData] = useState([{
        categoryId: "",
        sectorId: "",
    }]);
    const modalHeader = 'Profile Setup Completed';
    const modalSubHeader = 'Your profile details have been successfully added to your account. You can Edit/Delete those details whenever required.';
    const modalButton = 'Go to Dashboard';
    const navigate = useNavigate();

    const onSelectionChange = ({ sector, role }) => {
        setProfileData({
            sectorId: sector,
            categoryId: role,
        });
    }


    const createProfile = async () => {
        console.log('profileData', profileData, signUpdata, selectedOption);
        try {
            const response = await CallApi({
                url: `${process.env.REACT_APP_API_PROD}/api/v1/auth/signup`,
                method: 'POST',
                data: {
                    mobileNumber: signUpdata?.mobileNumber,
                    password: signUpdata?.password,
                    userType: selectedOption,
                    fullName: signUpdata?.fullName,
                    emailAddress: signUpdata?.email,
                    sectorId: profileData.sectorId?.id,
                    categoryId: profileData.categoryId?.id,
                }
            })
            console.log('response', response);
            if (response?.data?.status === 'success'){
                setShowModal(true);
            }else{
                toast.error(response?.data?.message)
            }
        } catch (error) {
            console.log('error', error);
        }
    }


    const handleNext = () => {
        setShowErrors(true);
        if (stepCount === 1 && selectedOption) {
            proceedToNextStep();
        } else if (isFormValid) {
            createProfile();
        }
    };

    const proceedToNextStep = () => {
        const nextStep = stepCount + 1;
        setStepCount(nextStep);
        setShowErrors(false);
        setIsFormValid(false);
        navigate(`/auth/signup?step=${nextStep}`, { replace: true });
    };

    const handleBack = () => {
        const prevStep = stepCount - 1;
        setStepCount(prevStep);
        setShowErrors(false);
        navigate(`/auth/signup?step=${prevStep}`, { replace: true });
    };

    const handleValidation = (isValid) => {
        setIsFormValid(isValid);
    };

    return (
        <div>
            <ToastContainer/>
            {stepCount > 1 && (
                <div onClick={handleBack} style={{
                    marginBottom: '20px'
                }}>
                    <ReactIcon name='IoArrowBackOutline' size='24px' />
                </div>
            )}
            <img
                src="/Assets/Modules/SignUp/Logo.svg"
                alt="Logo..."
                className={styles.imageView}
            />
            <div className={styles.stepIndicator}>STEP {stepCount}/2</div>
            <div className={styles.details}>
                <h1 className={styles.title}>Profile Setup</h1>
                <p className={styles.subtitle}>
                    Please enter your details and complete the profile.
                </p>
            </div>
            {stepCount === 1 && <div className={styles.optionsContainer} role="radiogroup" aria-label="Profile type selection">
                {options.map((option) => (
                    <SelectionOption
                        key={option.id}
                        isSelected={selectedOption === option.id}
                        title={option.title}
                        description={option.description}
                        onSelect={() => {
                            setSelectedOption(option.id);
                            // setIsValid(true);
                        }
                        }
                    />
                ))}
            </div>}
            {stepCount === 2 && <SelectionForm onValidation={handleValidation} showErrors={showErrors} onSelectionchange={onSelectionChange} />}
            {stepCount === 3 && <PersonalDetails type={selectedOption} onValidation={handleValidation} showErrors={showErrors} />}
            {stepCount === 4 && <AddressDetails onValidation={handleValidation} showErrors={showErrors} />}
            {stepCount === 5 && <IdentityDocuments onValidation={handleValidation} showErrors={showErrors} />}
            {stepCount < 5 && <div className={styles.actionButton}>
                <button
                    className={styles.nextButton}
                    onClick={handleNext}
                // disabled={!isValid}
                >
                    {stepCount > 1 ? 'Complete Profile' : 'Next'}
                </button>
            </div>}
            <Modal show={showModal} isClosable={true} onClose={() => setShowModal(false)}>
                <SignupSuccess modalHeader={modalHeader} modalSubHeader={modalSubHeader} modalButton={modalButton} onBtnClick={() => signIn()} />
            </Modal>
        </div>
    )
}
