export const faqData = {
    'General': [
        {
            id: 1,
            icon: 'IoLocationOutline',
            question: 'What locations are we serving ?',
            answer: 'We currently serve Mumbai and will be expanding to other cities soon. Stay tuned!'
        },
        {
            id: 2,
            icon: 'FaMobileAlt',
            question: 'Is there a mobile app for the platform?',
            answer: 'Yes, we have a mobile app available for Android devices, providing easy access to all our services.'
        },
        {
            id: 3,
            icon: 'FaHeadset',
            question: 'How can I get in touch with customer support?',
            answer: 'You can reach customer support through our help center, via email, chat, or phone for assistance.'
        },
        {
            id: 4,
            icon: 'FaClock',
            question: 'What are your operating hours?',
            answer: 'We operate from 8 AM to 8 PM to provide you with services whenever you need them.'
        }
    ],
    'Account & Registration': [
        {
            id: 5,
            icon: 'FaUserPlus',
            question: 'How do I create an account on the platform?',
            answer: 'If you are a consumer who needs our services please on the connect app sign up page, enter your mobile number, verify it via OTP, and provide your personal details to set up your account.'
        },
        {
            id: 6,
            icon: 'FaUserEdit',
            question: 'Can I change my account details after registration?',
            answer: 'Yes, you can update your personal details, email address, and phone number through your account settings.'
        },
        {
            id: 7,
            icon: 'FaKey',
            question: 'What should I do if I forget my password?',
            answer: 'If you are a service provider, Click on the "Forgot Password" option on our Connect website login page, and follow the instructions to reset it.'
        },
        {
            id: 8,
            icon: 'FaCheckCircle',
            question: 'How do I verify my account?',
            answer: 'Account verification is done via OTP sent to your mobile number during the registration process.'
        },
        {
            id: 9,
            icon: 'FaUserMinus',
            question: 'Can I delete my account?',
            answer: 'Yes, you can request account deletion by contacting our support team through the app or website.'
        },
        {
            id: 10,
            icon: 'FaExclamationTriangle',
            question: 'What should I do if I face issues while registering?',
            answer: 'If you encounter any issues during registration, please contact our support team via chat or email for assistance.'
        }
    ],
    'Booking & Cancellation': [
        {
            id: 11,
            icon: 'FaTimesCircle',
            question: 'How do I cancel my service ?',
            answer: 'Go to your bookings, select "Cancel," enter the reason, and submit your request.'
        },
        {
            id: 12,
            icon: 'FaComments',
            question: 'Is there a way to communicate directly with my assigned service provider?',
            answer: `Yes, you'll receive their contact details after confirmation to discuss any specifics.Our support team is also here to help if needed.`
        },
        {
            id: 13,
            icon: 'FaToolbox',
            question: 'Do I need to provide any equipment or materials for the service?',
            answer: 'Our services are performed by professionals who bring their own tools and materials for the designated work. For any additional necessities which are beyond the scope of the work, customers may share trivial items.'
        },
        {
            id: 14,
            icon: 'FaCalendarAlt',
            question: 'Can I reschedule or cancel my booking?',
            answer: 'Yes, go to "Bookings" in the app, select "Reschedule" to change the date or "Cancel" to submit your cancellation request.'
        },
        {
            id: 15,
            icon: 'FaBookmark',
            question: 'How do I book a service?',
            answer: 'Open the Connect app, select your desired service, choose a time slot, and confirm your booking after payment.'
        },
        {
            id: 16,
            icon: 'FaBan',
            question: 'Can I cancel a service after a service provider has been assigned?',
            answer: 'Cancellation after assigning a service provider is not possible to ensure their time and efforts are respected.'
        }
    ],
    'Payment Queries': [
        {
            id: 17,
            icon: 'FaMoneyBillWave',
            question: 'Is COD Available for orders?',
            answer: 'No, we currently do not accept Cash on Delivery (COD). All orders need to be prepaid at the time of booking to confirm the service.'
        },
        {
            id: 18,
            icon: 'FaCreditCard',
            question: 'How do I make a payment for a service?',
            answer: 'Payments can be made via credit/debit card, net banking, UPI, or mobile wallets during the booking process.'
        },
        {
            id: 19,
            icon: 'FaLock',
            question: 'Are payments secure on your platform?',
            answer: 'Yes, following the RBI guidelines we use secure payment gateways and encryption to ensure your payment details are safe.'
        },
        {
            id: 20,
            icon: 'FaShoppingCart',
            question: 'Can I pay for multiple services at once?',
            answer: 'Currently, each service needs to be paid individually during the booking process.'
        },
        {
            id: 21,
            icon: 'FaExclamationCircle',
            question: 'What should I do if my payment fails?',
            answer: 'If your payment fails, check your internet connection and payment method. If the issue persists, contact our support team for help.'
        },
        {
            id: 22,
            icon: 'FaUndo',
            question: 'Do you provide refunds?',
            answer: 'Yes, refunds are processed as per our cancellation policy. They are typically credited within 5-7 working days to your original payment method.'
        }
    ],
    'Service Related': [
        {
            id: 23,
            icon: 'FaExclamation',
            question: 'What should I do if there is an issue or discrepancy with the service provided?',
            answer: `Contact our support team via email, call, or chat, and we'll resolve it promptly.`,
        },
        {
            id: 24,
            icon: 'FaFlag',
            question: 'How do I report inappropriate behavior or an issue with a service provider?',
            answer: 'If you experience any inappropriate behavior, please report it immediately to our support team, and we will investigate the matter.'
        },
        {
            id: 25,
            icon: 'FaExchangeAlt',
            question: 'Can I change my service provider after booking?',
            answer: 'Once your service provider is confirmed, you cannot change the service provider.'
        },
        {
            id: 26,
            icon: 'FaFrown',
            question: `What should I do if I'm not satisfied with the service provided?`,
            answer: 'If you are unhappy with the service, please contact our support team immediately so we can resolve the issue to your satisfaction.'
        },
        {
            id: 27,
            icon: 'FaHandshake',
            question: `What happens if the service provider doesn't show up?`,
            answer: `In the unlikely event that your service provider doesn’t show up, please contact our support team, and we’ll arrange for a replacement.`
        },
        {
            id: 28,
            icon: 'FaCheckDouble',
            question: `What happens if my service provider is late?`,
            answer: `If your service provider is delayed, please contact our support team, and we will assist you in resolving the issue.`
        }
    ],
    'Service Partner Queries': [
        {
            id: 29,
            icon: 'FaUserTie',
            question: `Is a company license required to join connect as a service partner?`,
            answer:`If you serve as an individual professional then, no company license is required. For service as a company it is mandatory to submit your business details during registration.`
        },
        {
            id: 30,
            icon: 'FaHandshake',
            question: `Can I change my rate for the service after I registered on the Connect platform?`,
            answer: `Yes, from the connect website portal, under your account settings you can always update your service details including rates.`
        },
        {
            id: 31,
            icon: 'FaUserCheck',
            question: `Do I need to have an account in the connect app to register for my business?`,
            answer: `It is not mandatory to have the Connect app account if you are only interested to register your service via our website. But, it is recommended to register a consumer account on the Connect app so that you can also hire another 3rd party professional related to your business.`
        },
        {
            id: 32,
            icon: 'FaUserTie',
            question: `Can I track my past service records?`,
            answer: `Yes, on our Connect web portal under account settings, you can see the history of all of your services.`
        },
        {
            id: 33,
            icon: 'FaUserTie',
            question: `What if customers cancel my service booking before I attend it?`,
            answer: `Connect provides you with a real time update on your service records. In any cases, if customers cancel your service booking that has already been confirmed by you, connect will supervise the matter. For more details please read our Terms & Conditions or reach out to our support team.`
        },
        {
            id: 34,
            icon: 'FaUserTie',
            question: `Can I add multiple services for my account?`,
            answer: `Yes, on our Connect web portal under account settings, service details, you may add multiple services each having specific details. Additionally, you can manage your service by activating or deactivating them.`
        }
    ]
}