import React, { useState, useRef, useEffect } from 'react';
import styles from './PersonalDetails.module.css';
import { DatePicker } from '../utils/DatePicker';

export const PersonalDetails = ({ type, onValidation, showErrors }) => {

    const [formData, setFormData] = useState({
        companyName: '',
        companyType: '',
        email: '',
        gstNumber: '',
        phoneNumber: '',
        bio: '',
        profileImage: null,
        fullName: '',
        dateOfBirth: ''
    });
    const [imageError, setImageError] = useState('');
    const fileInputRef = useRef(null);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        validateForm();
    }, [formData, showErrors]);

    const validateForm = () => {
        let isValid = true;
        let newErrors = {};

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^[6-9]\d{9}$/;
        const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

        if (type === 'business') {
            if (formData.companyName.trim() === '') {
                newErrors.companyName = 'Company name is required';
                isValid = false;
            }
            if (formData.companyType === '') {
                newErrors.companyType = 'Company type is required';
                isValid = false;
            }
            if (!gstRegex.test(formData.gstNumber)) {
                newErrors.gstNumber = 'Invalid GST number';
                isValid = false;
            }
        } else {
            if (formData.fullName.trim() === '') {
                newErrors.fullName = 'Full name is required';
                isValid = false;
            }
        }

        if (!emailRegex.test(formData.email)) {
            newErrors.email = 'Invalid email address';
            isValid = false;
        }
        if (!phoneRegex.test(formData.phoneNumber)) {
            newErrors.phoneNumber = 'Invalid phone number';
            isValid = false;
        }
        if (formData.dateOfBirth === '') {
            newErrors.dateOfBirth = 'Date of birth is required';
            isValid = false;
        } else {
            const dob = new Date(formData.dateOfBirth);
            const today = new Date();
            if (dob >= today) {
                newErrors.dateOfBirth = 'Invalid date of birth';
                isValid = false;
            }
        }
        if(formData.bio.trim() === '') {
            newErrors.bio = 'Bio is required';
            isValid = false;
        }
        if (formData.profileImage === null) {
            newErrors.profileImage = 'Profile image is required';
            isValid = false;
        }

        setErrors(newErrors);
        onValidation(isValid);
        return isValid;
    };

    const handleImageUpload = (file) => {
        if (!file) return;

        if (!file.type.startsWith('image/')) {
            setImageError('Please upload an image file');
            return;
        }

        if (file.size > 5 * 1024 * 1024) {
            setImageError('Image must be less than 5MB');
            return;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
            setFormData(prev => ({ ...prev, profileImage: reader.result }));
        };
        reader.readAsDataURL(file);
        setImageError('');
    };

    const handleInputChange = (name, value) => {
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log('Form submitted', formData);
        } catch (error) {
            console.error('Failed to update profile:', error);
        }
    };

    return (
        <div className={styles.container}>
            <form onSubmit={handleSubmit} className={styles.form}>
                <h2 className={styles.title}>Personal Details</h2>

                <div className={styles.imageUploader}>
                    <button
                        type="button"
                        onClick={() => fileInputRef.current?.click()}
                        className={styles.uploadButton}
                        aria-label="Upload profile picture"
                    >
                        {formData.profileImage ? (
                            <img
                                src={formData.profileImage}
                                alt="Profile"
                                className={styles.profileImage}
                            />
                        ) : (
                            <div className={styles.placeholder}>
                                <img
                                    src="/Assets/Modules/ProfileStep/camera.svg"
                                    alt=""
                                    className={styles.cameraIcon}
                                />
                            </div>
                        )}
                    </button>
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e.target.files?.[0])}
                        className={styles.fileInput}
                        aria-label="Upload profile picture"
                    />
                </div>
                {showErrors && errors.profileImage && (
                    <div className={styles.error} role="alert">
                        {errors.profileImage}
                    </div>
                )}
                {type === 'business' ? <div className={styles.inputGroup}>
                    <label className={styles.label}>
                        Company Name
                        <span className={styles.required}>*</span>
                    </label>
                    <input
                        type="text"
                        value={formData.companyName}
                        onChange={(e) => handleInputChange('companyName', e.target.value)}
                        className={styles.input}
                        required
                        aria-required="true"
                    />
                    {showErrors && errors.companyName && (
                        <div className={styles.error}>{errors.companyName}</div>
                    )}
                </div> :
                    <div className={styles.inputGroup}>
                        <label className={styles.label}>
                            Full Name
                            <span className={styles.required}>*</span>
                        </label>
                        <input
                            type="text"
                            value={formData.fullName}
                            onChange={(e) => handleInputChange('fullName', e.target.value)}
                            className={styles.input}
                            required
                            aria-required="true"
                            placeholder='Enter your full name'
                        />
                        {showErrors && errors.fullName && (
                            <div className={styles.error}>{errors.fullName}</div>
                        )}
                    </div>}
                {type === 'business' && <div className={styles.inputGroup}>
                    <label className={styles.label}>
                        Company Type
                        <span className={styles.required}>*</span>
                    </label>
                    <div className={styles.selectWrapper}>
                        <select
                            value={formData.companyType}
                            onChange={(e) => handleInputChange('companyType', e.target.value)}
                            className={styles.select}
                            required
                            aria-required="true"
                        >
                            <option value="">Select</option>
                            <option value="private">Private Limited</option>
                            <option value="public">Public Limited</option>
                            <option value="partnership">Partnership</option>
                        </select>
                    </div>
                    {showErrors && errors.companyType && (
                        <div className={styles.error}>{errors.companyType}</div>
                    )}
                </div>}

                <div className={styles.inputGroup}>
                    <label className={styles.label}>
                        Email Address
                        <span className={styles.required}>*</span>
                    </label>
                    <input
                        type="email"
                        value={formData.email}
                        onChange={(e) => handleInputChange('email', e.target.value)}
                        className={styles.input}
                        required
                        aria-required="true"
                        placeholder='Enter your email address'
                    />
                    {showErrors && errors.email && (
                        <div className={styles.error}>{errors.email}</div>
                    )}
                </div>

                {type === 'business' && <div className={styles.inputGroup}>
                    <label className={styles.label}>
                        GST Number
                        <span className={styles.required}>*</span>
                    </label>
                    <input
                        type="text"
                        value={formData.gstNumber}
                        onChange={(e) => handleInputChange('gstNumber', e.target.value)}
                        className={styles.input}
                        required
                        aria-required="true"
                    />
                    {showErrors && errors.gstNumber && (
                        <div className={styles.error}>{errors.gstNumber}</div>
                    )}
                </div>}

                <div className={styles.inputGroup}>
                    <label className={styles.label}>
                        Phone Number
                        <span className={styles.required}>*</span>
                    </label>
                    <div className={styles.phoneInput}>
                        <div className={styles.countryCode}>
                            <img src="/Assets/Modules/ProfileStep/india-flag.svg" alt="" className={styles.flag} />
                            <span>+91</span>
                            <img src="/Assets/Modules/ProfileStep/chevron-down.svg" alt="" className={styles.chevron} />
                        </div>
                        <input
                            type="tel"
                            value={formData.phoneNumber}
                            onChange={(e) => handleInputChange('phoneNumber', e.target.value)}
                            className={styles.phoneNumber}
                            required
                            aria-required="true"
                            pattern="[0-9]{10}"
                            placeholder='0000000000'
                        />
                    </div>
                    {showErrors && errors.phoneNumber && (
                        <div className={styles.error}>{errors.phoneNumber}</div>
                    )}
                </div>
                <div className={styles.inputGroup}>
                    <label className={styles.label}>
                        Date of Birth
                        <span className={styles.required}>*</span>
                    </label>
                    <DatePicker
                        value={formData.dateOfBirth}
                        onChange={(e) => handleInputChange('dateOfBirth', e.target.value)}
                        required={true}
                    />
                    {showErrors && errors.dateOfBirth && (
                        <div className={styles.error}>{errors.dateOfBirth}</div>
                    )}
                </div>
                <div className={styles.inputGroup}>
                    <label className={styles.label}>Bio</label>
                    <textarea
                        value={formData.bio}
                        onChange={(e) => handleInputChange('bio', e.target.value)}
                        className={styles.textarea}
                        rows={4}
                        placeholder='Enter a description...'
                    />
                    {showErrors && errors.bio && (
                        <div className={styles.error}>{errors.bio}</div>
                    )}
                </div>
            </form>
        </div>
    );
};