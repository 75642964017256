import React, { useEffect, useRef, useState } from 'react';
import styles from './Header.module.css';
import ReactIcon from '../utils/ReactIcon';
import { useLocation, useNavigate } from 'react-router-dom';

export const navItems = [
    { text: 'Home', width: '51px', link: '/' },
    { text: 'About us', width: '76px', link: '#support' },
    { text: 'Services', width: '74px', link: '#services' },
    { text: 'Blogs', width: '48px', link: '/blogs' },
    { text: 'FAQs', width: '47px', link: '/faq' },
    { text: 'Contact us', width: '91px', link: '#contact' }
];

const menuItems = [
    { icon: 'IoMdHome', text: 'Dashboard' },
    { icon: 'BsPersonCircle', text: 'My Profile' },
    { icon: 'IoIosSettings', text: 'Settings' },
    { icon: 'IoIosHelpCircle', text: 'Help & Support', active: true },
    { icon: 'IoLogOut', text: 'Logout', isLogout: true }
];

const chevronUp = '/Assets/Modules/ProfileStep/chevron-up.svg';
const chevronDown = '/Assets/Modules/ProfileStep/chevron-down.svg';

function Header({ profileShown = true }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    // const [isLoggedIn, setIsLoggedIn] = useState(true);
    const isLoggedIn = true;
    const [activeButton, setActiveButton] = useState('login');

    const location = useLocation();
    const [activeSection, setActiveSection] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            const sections = ['home', 'services', 'support', 'faq', 'footer', 'contact', 'blogs']; // Add all your sections here
            let current = '';

            for (const section of sections) {
                const element = document.getElementById(section);
                if (element) {
                    const rect = element.getBoundingClientRect();
                    if (rect.top <= window.innerHeight / 3 && rect.bottom >= window.innerHeight / 3) {
                        current = section;
                        break;
                    }

                }
            }

            const lastSection = document.getElementById('footer') || document.getElementById('contact');
            if (lastSection && window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
                current = 'contact';
            }

            setActiveSection(current);
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const isActive = (link) => {
        if (link === '/') {
            return (activeSection === 'home' || (activeSection === '' && location.pathname === '/'));
        }
        if (link === '/faq') {
            return activeSection === 'faq' || location.pathname === '/faq';
        }
        if (link === '/blogs') {
            return activeSection === 'blogs' || location.pathname === '/blogs';
        }
        if (link === '#contact') {
            return activeSection === 'contact' && !['blogs', 'faq'].includes(location.pathname.slice(1));
        }
        return activeSection === link.replace('#', '') || location.pathname.startsWith(link);
    };

    const menuRef = useRef(null);
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsMenuOpen(false);
        }
    };

    const handleButtonClick = (button) => {
        setActiveButton(button);
        const data = { button };
        const queryString = new URLSearchParams(data).toString();
        navigate(`/auth/signup?${queryString}`);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleNavItemClick = (link) => {
        if (link === '#support') {
            const supportSection = document.getElementById('support');
            if (supportSection) {
                supportSection.scrollIntoView({ behavior: 'smooth' });
            } else {
                navigate('/');
            }
        } else if (link === '#services') {
            const servicesSection = document.getElementById('services');
            if (servicesSection) {
                servicesSection.scrollIntoView({ behavior: 'smooth' });
            } else {
                navigate('/');
            }
        } else if (link === '#contact') {
            const contactSection = document.getElementById('footer');
            if (contactSection) {
                contactSection.scrollIntoView({ behavior: 'smooth' });
            } else {
                navigate('/');
            }
        } else if (link === '/') {
            const homeSection = document.getElementById('home');
            if (homeSection) {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            } else {
                navigate('/');
            }
        }
        else {
            navigate(link);
        }
    };

    return (
        <header className={styles.header}>
            <nav ref={menuRef} className={styles.navbar}>
                <div onClick={() => navigate('/')} className={styles.brandSection}>
                    <img
                        loading="lazy"
                        src="/Assets/Modules/SignUp/Logo.svg"
                        className={styles.brandLogo}
                        alt="Connect brand logo"
                    />
                    <div className={styles.brandName}>Connect</div>
                </div>
                <button
                    className={styles.mobileMenuButton}
                    onClick={toggleMobileMenu}
                    aria-expanded={isMobileMenuOpen}
                    aria-label="Toggle mobile menu"
                >
                    <ReactIcon name="FaBars" size="24px" />
                </button>
                <div className={styles.navLinks}>
                    {navItems.map((item, index) => (
                        <div
                            key={index}
                            className={`${styles.navLink} ${isActive(item.link) ? styles.activeNavLink : ''}`}
                            style={{ width: item.width }}
                            tabIndex="0"
                            role="button"
                            onClick={(e) => {
                                e.preventDefault();
                                handleNavItemClick(item.link);
                            }}
                        >
                            {item.text}
                        </div>
                    ))}
                </div>

                <div className={styles.profileSection}>
                    <div
                        className={styles.profileContainer}
                        onClick={!isLoggedIn ? toggleMenu : null}
                        tabIndex="0"
                        role="button"
                        aria-expanded={isMenuOpen}
                        aria-haspopup="true"
                    >
                        {profileShown && <div>
                            {isLoggedIn ? <nav className={styles.authContainer} aria-label="Authentication">
                                <button
                                    className={`${styles.authButton} ${activeButton === 'login' ? styles.active : styles.nonActive}`}
                                    onClick={() => handleButtonClick('login')}
                                    tabIndex={0}
                                    aria-label="Login to your account"
                                >
                                    Login
                                </button>
                                <button
                                    className={`${styles.authButton} ${activeButton === 'signup' ? styles.active : styles.nonActive}`}
                                    onClick={() => handleButtonClick('signup')}
                                    tabIndex={0}
                                    aria-label="Create new account"
                                >
                                    Signup
                                </button>
                            </nav> :
                                <>
                                    <div className={styles.avatarWrapper}>
                                        <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/fb60b6f73fe84ee99aaecdaf0cb76d4f/d5ed92e02cc661cd7998a57f37d11b8467e6e1cd6861d7a393a0de1932c8dbac?apiKey=fb60b6f73fe84ee99aaecdaf0cb76d4f&"
                                            className={styles.avatarImage}
                                            alt="Samantha Roy"
                                        />
                                        <div className={styles.onlineIndicator} aria-hidden="true" />
                                    </div>
                                    <div className={styles.userInfo}>
                                        <div className={styles.userName}>Samantha Roy</div>
                                        <div className={styles.userEmail}>Sam_roy21@gmail.com</div>
                                    </div>
                                    <div className={styles.dropdownIcon}>
                                        <img
                                            loading="lazy"
                                            src={isMenuOpen ? chevronUp : chevronDown}
                                            className={styles.dropdownImage}
                                            alt=""
                                            aria-hidden="true"
                                        />
                                    </div>
                                </>
                            }
                        </div>}
                    </div>

                    {isMenuOpen && (
                        <div className={styles.menuContainer} role="menu">
                            <div className={styles.menuContent}>
                                {menuItems.map((item, index) => (
                                    <div
                                        key={index}
                                        className={`${styles.menuItem} ${item.isLogout ? styles.logoutItem : ''}`}
                                        role="menuitem"
                                        tabIndex="0"
                                    >
                                        {item.active && <div className={styles.activeIndicator} aria-hidden="true" />}
                                        <ReactIcon name={item.icon} size="24px" color={item.isLogout && '#eb4c4c'} />
                                        <div className={styles.menuText}>{item.text}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>


            </nav>
            {isMobileMenuOpen && (
                <div className={styles.mobileMenu} role="menu">
                    {navItems.map((item, index) => (
                        <div
                            key={index}
                            className={styles.mobileMenuItem}
                            role="menuitem"
                            tabIndex="0"
                            onClick={(e) => {
                                e.preventDefault();
                                handleNavItemClick(item.link);
                                setIsMobileMenuOpen(false);
                            }}
                        >
                            {item.text}
                        </div>
                    ))}
                </div>
            )}
        </header>
    );
}

export default Header;