import React, { useState } from 'react';
import styles from './QueryModal.module.css';
import SignupSuccess from '../ProfileStep/SignupSuccess';

export const QueryModal = ({ onClose }) => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });
    const [querySent, setQuerySent] = useState(false);
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: ''
            }));
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.fullName) newErrors.fullName = 'Full Name is required.';
        if (!formData.email) {
            newErrors.email = 'Email is required.';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = 'Please enter a valid email address.';
        }
        if (!formData.phone) {
            newErrors.phone = 'Phone number is required.';
        } else if (!/^\d{10}$/.test(formData.phone)) {
            newErrors.phone = 'Please enter a valid 10-digit phone number.';
        }
        if (!formData.subject) newErrors.subject = 'Subject is required.';
        if (!formData.message) newErrors.message = 'Message is required.';
        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setQuerySent(true);
            console.log(formData);
        }
    };
    const modalHeader = 'Query Sent Successfully!';
    const modalSubHeader = 'Your query, issue or suggestion has been sent successfully. We’ll get back to you within 24-48hrs.';
    const modalButton = '';

    return (
        <>
            {!querySent ? <div className={styles.formContainer} role="dialog" aria-modal="true" aria-labelledby="modalTitle">
                <div className={styles.formContent}>
                    <div className={styles.header}>
                        <img
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/12295ef3c2d54f4ef72ad47c2703766523c8a43de31e53214a69f0c43b566820?placeholderIfAbsent=true&apiKey=94d4d667b6ca4a158e5203914f66f311"
                            alt=""
                            className={styles.headerIcon}
                            aria-hidden="true"
                        />
                        <div className={styles.headerText}>
                            <h1 id="modalTitle" className={styles.title}>Get in Touch</h1>
                            <p className={styles.description}>
                                Let us know your query, issue or suggestion by filling out the
                                form. We'll get back to you as soon as possible.
                            </p>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit} className={styles.form} noValidate>
                        <div className={styles.inputField}>
                            <label htmlFor="fullName" className={styles.label}>
                                Full Name<span className={styles.required} aria-hidden="true">*</span>
                            </label>
                            <input
                                type="text"
                                id="fullName"
                                name="fullName"
                                className={`${styles.input} ${errors.fullName ? styles.errorInput : ''}`}
                                value={formData.fullName}
                                onChange={handleInputChange}
                                placeholder="Enter your full name"
                                required
                                aria-required="true"
                            />
                            {errors.fullName && <p className={styles.errorText}>{errors.fullName}</p>}
                        </div>

                        <div className={styles.inputField}>
                            <label htmlFor="email" className={styles.label}>
                                Email Address<span className={styles.required} aria-hidden="true">*</span>
                            </label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                className={`${styles.input} ${errors.email ? styles.errorInput : ''}`}
                                value={formData.email}
                                onChange={handleInputChange}
                                placeholder="Enter your email address"
                                required
                                aria-required="true"
                            />
                            {errors.email && <p className={styles.errorText}>{errors.email}</p>}
                        </div>

                        <div className={styles.inputField}>
                            <label htmlFor="phone" className={styles.label}>
                                Phone Number<span className={styles.required} aria-hidden="true">*</span>
                            </label>
                            <div className={styles.phoneInputWrapper}
                            style={{
                                borderColor : errors.phone ? 'red' : ''
                            }}
                            >
                                <div className={styles.countryCode}>
                                    <img
                                        src="/Assets/Modules/ProfileStep/india-flag.svg"
                                        alt=""
                                        className={styles.countryFlag}
                                        aria-hidden="true"
                                    />
                                    <span className={styles.countryDialCode}>+91</span>
                                    {/* <img
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/3a45308ada221a1231bbd8003d319bdc7c28b29b4cf9bb284e427cdd6c4791e3?placeholderIfAbsent=true&apiKey=94d4d667b6ca4a158e5203914f66f311"
                                        alt=""
                                        className={styles.dropdownArrow}
                                        aria-hidden="true"
                                    /> */}
                                </div>
                                <input
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    className={styles.phoneInput}
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    placeholder="0000000000"
                                    required
                                    aria-required="true"
                                />
                            </div>
                            {errors.phone && <p className={styles.errorText}>{errors.phone}</p>}
                        </div>

                        <div className={styles.inputField}>
                            <label htmlFor="subject" className={styles.label}>
                                What can we help you with<span className={styles.required} aria-hidden="true">*</span>
                            </label>
                            <div 
                            // className={styles.selectWrapper}
                                className={`${styles.selectWrapper} ${errors.subject ? styles.errorInput : ''}`}
                            >
                                <select
                                    id="subject"
                                    name="subject"
                                    className={styles.select}
                                    value={formData.subject}
                                    onChange={handleInputChange}
                                    required
                                    aria-required="true"
                                >
                                    <option value="">Select subject</option>
                                    <option value="general">General Inquiry</option>
                                    <option value="technical">Technical Support</option>
                                    <option value="billing">Billing Question</option>
                                    <option value="other">Other</option>
                                </select>
                                <img
                                    src="/Assets/Modules/ProfileStep/chevron-down.svg"
                                    alt=""
                                    className={styles.selectArrow}
                                    aria-hidden="true"
                                />
                            </div>
                            {errors.subject && <p className={styles.errorText}>{errors.subject}</p>}
                        </div>

                        <div className={styles.textareaField}>
                            <label htmlFor="message" className={styles.label}>
                                Message<span className={styles.required} aria-hidden="true">*</span>
                            </label>
                            <textarea
                                id="message"
                                name="message"
                                className={styles.textarea}
                                style={{
                                    borderColor : errors.message ? 'red' : ''
                                }}
                                value={formData.message}
                                onChange={handleInputChange}
                                placeholder="Please describe your query/issue..."
                                required
                                aria-required="true"
                            />
                            {errors.message && <p className={styles.errorText}>{errors.message}</p>}
                        </div>

                        <button type="submit" className={styles.submitButton}>
                            Send
                        </button>
                    </form>
                </div>
            </div> :
                <SignupSuccess modalHeader={modalHeader} modalSubHeader={modalSubHeader} modalButton={modalButton} onBtnClick={() => { }} btnShow={false} />}
        </>
    );
};