import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./index.css";
import { BrowserRouter, createBrowserRouter, RouterProvider } from "react-router-dom";
import { BlogList } from './Modules/Home/Blogs/BlogList';
import { BlogDetails } from './Modules/Home/Blogs/BlogDetails';
import { FaqList } from './Modules/Home/FAQ/FaqList';
import { Terms } from './Modules/Documents/Terms';
import { Privacy } from './Modules/Documents/Privacy';
import Refund from './Modules/Documents/Refund';
import SignUp from './Modules/SignUp/SignUp';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: 'blogs', 
        element: <BlogList />,
      },
      {
        path: 'blogdetails',
        element: <BlogDetails />,
      },
      {
        path: 'faq',
        element: <FaqList/>
      },
      {
        path: 'terms',
        element: <Terms />,
      },
      {
        path: 'privacy',
        element: <Privacy />,
      },
      {
        path: 'refund',
        element: <Refund />,
      },
      {
        path: 'auth/signup',
        element: <SignUp />,
      }
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);