import React from 'react';
import styles from './Selection.module.css';

export default function SelectionButton({
    option,
    isSelected,
    onClick,
    ariaLabelledBy
}) {
    return (
        <button
            className={`${styles.optionButton} ${isSelected ? styles.selected : ''}`}
            onClick={onClick}
            aria-pressed={isSelected}
            aria-labelledby={ariaLabelledBy}
        >
            {option}
        </button>
    );
}