// FILEPATH: c:/ConnectProject/Connect-Landing-Web-Front/src/Modules/utils/SelectionGroup.jsx

import React from 'react';
import styles from './Selection.module.css';
import SelectionButton from './SelectionButton';

export default function SelectionGroup({
    title,
    options,
    selectedOption,
    selectedOptions,
    onSelect,
    multiSelect,
    ariaLabel,
    valueKey,
    labelKey,
}) {
    const isObjectArray = typeof options[0] === 'object';

    const getLabelValue = (option) => {
        if (!isObjectArray) return option;
        return labelKey ? option[labelKey] : Object.values(option)[0];
    };

    const handleOptionClick = (option) => {
        if (multiSelect) {
            const label = getLabelValue(option);
            const updatedSelection = selectedOptions.some(item => getLabelValue(item) === label)
                ? selectedOptions.filter(item => getLabelValue(item) !== label)
                : [...selectedOptions, option];
            onSelect(updatedSelection);
        } else {
            if (isObjectArray) {
                onSelect(selectedOption && getLabelValue(selectedOption) === getLabelValue(option) ? null : option);
            } else {
                onSelect(selectedOption === option ? null : option);
            }
        }
    };

    const rows = [];
    for (let i = 0; i < options.length; i += 3) {
        rows.push(options.slice(i, i + 3));
    }

    return (
        <div className={styles.selectionGroup} role="group" aria-label={ariaLabel}>
            <div className={styles.sectionTitle} id={`${title}-label`}>{title}</div>
            <div className={styles.optionsGrid}>
                {rows.map((row, rowIndex) => (
                    <div key={rowIndex} className={styles.optionRow}>
                        {row.map((option, index) => {
                            const value = isObjectArray ? (valueKey ? option[valueKey] : Object.values(option)[0]) : option;
                            const label = getLabelValue(option);
                            return (
                                <SelectionButton
                                    key={isObjectArray ? value : index}
                                    option={label}
                                    isSelected={multiSelect
                                        ? selectedOptions?.some(item => getLabelValue(item) === label)
                                        : isObjectArray
                                            ? selectedOption && getLabelValue(selectedOption) === label
                                            : selectedOption === label
                                    }
                                    onClick={() => handleOptionClick(option)}
                                    ariaLabelledBy={`${title}-label`}
                                />
                            );
                        })}
                    </div>
                ))}
            </div>
        </div>
    );
}
