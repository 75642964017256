import React, { useEffect, useState } from 'react';
import styles from './FaqList.module.css';
import faqStyles from '../FaqSection.module.css';
import Header from '../Header';
import { Footer } from '../Footer';
import ReactIcon from '../../utils/ReactIcon';
import { QueryModal } from '../../utils/QueryModal';
import { faqData as faqItems } from './faqdata';
import { useLocation } from 'react-router-dom';
import { faqCategories } from '../FaqSection';
import CustomModal from '../../utils/CustomModal';
import { Helmet } from 'react-helmet';
const tags = [
    { id: 1, label: 'General' },
    { id: 2, label: 'Account & Registration' },
    { id: 3, label: 'Booking & Cancellation' },
    { id: 4, label: 'Payment Queries' },
    { id: 5, label: 'Service Related' },
    { id: 6, label: 'Service Partner Queries' }
];
export const FaqList = () => {
    const [activeTag, setActiveTag] = useState('General');
    const [searchQuery, setSearchQuery] = useState('');
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.selectedCategory) {
            setActiveTag(location.state.selectedCategory)
        }
    }, [location]);

    const handleSearch = (e) => {
        e.preventDefault();
        setSearchQuery(e.target.value);
    };

    const handleTagClick = (tagId) => {
        setActiveTag(tagId);
    };

    const filteredFaqs = faqItems[activeTag]?.filter(item =>
        item.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.answer.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": filteredFaqs.map(faq => ({
            "@type": "Question",
            "name": faq.question,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": faq.answer
            }
        }))
    };
    
    return (
        <>
            <Helmet>
                <title>FAQ - Got Any Questions?</title>
                <meta name="description" content="Find answers to the most common queries about our services. Check out our FAQs for a smoother experience." />
                <link rel="canonical" href="https://connectsolutionss.in/faq" />
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <Header />
            <main className={styles.faqContainer}>
                <section className={styles.heroSection}>
                    <div className={styles.contentWrapper}>
                        <div className={styles.headerContent}>
                            <div className={styles.headerText}>
                                <div className={styles.label}>FAQs</div>
                                <h1 className={styles.title}>Got Any Question?</h1>
                                <div className={styles.subtitle}>
                                    Don't worry, we've got the answers. Check out the most common
                                    queries and make your experience even smoother.
                                </div>
                            </div>
                            <form className={styles.searchContainer} onSubmit={(e) => e.preventDefault()}>
                                <div className={styles.searchInput}>
                                    <label htmlFor="searchFaq" className={styles.visuallyHidden}>
                                        Search FAQs
                                    </label>
                                    <ReactIcon name='IoIosSearch' size='24px' color='#667085' />
                                    <input
                                        type="search"
                                        id="searchFaq"
                                        value={searchQuery}
                                        onChange={handleSearch}
                                        placeholder="Type here"
                                        className={styles.searchField}
                                        aria-label="Search FAQs"
                                    />
                                    <button
                                        type="submit"
                                        className={styles.searchButton}
                                        aria-label="Submit search"
                                    >
                                        Search
                                    </button>
                                </div>
                            </form>
                        </div>
                        <nav className={styles.tagContainer} role="tablist">
                            {tags.map(tag => (
                                <button
                                    key={tag.label}
                                    role="tab"
                                    aria-selected={activeTag === tag.label}
                                    aria-controls={`panel-${tag.label}`}
                                    className={`${styles.tag} ${activeTag === tag.label ? styles.activeTag : ''}`}
                                    onClick={() => handleTagClick(tag.label)}
                                >
                                    {tag.label}
                                </button>
                            ))}
                        </nav>
                    </div>
                </section>
                <section className={styles.faqContent}>
                    <div className={styles.faqGrid}>
                        <div className={styles.faqHeader}>
                            <h2 className={styles.faqHeaderTitle}>{activeTag} FAQs</h2>
                            <p className={styles.faqHeaderDescription}>
                                {faqCategories.find(cat => cat.title === activeTag)?.description}
                            </p>
                        </div>
                        <div
                            className={styles.faqList}
                            role="tabpanel"
                            id={`panel-${activeTag}`}
                        >
                            {filteredFaqs.map((item) => (
                                <div key={item.id} className={styles.faqItem}>
                                    <div className={styles.iconWrapper}>
                                        <ReactIcon name={item.icon} size='20px' color='#122577' />
                                    </div>
                                    <div className={styles.faqItemContent}>
                                        <h3 className={styles.faqQuestion}>{item.question}</h3>
                                        <p className={styles.faqAnswer}>{item.answer}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <section className={faqStyles.banner} style={{ display: 'flex', width: '1440px', alignSelf: 'center', margin: '80px 0' }}>
                    <div className={faqStyles.bannerContent}>
                        <img
                            src="/Assets/Modules/Home/FAQ/getintouch.svg"
                            alt="Our support team"
                            className={faqStyles.bannerImage}
                        />
                        <div className={faqStyles.bannerText}>
                            <h2 className={faqStyles.bannerTitle}>Still have questions?</h2>
                            <p className={faqStyles.bannerDescription}>
                                Can't find the answer you're looking for? Please chat to our friendly team.
                            </p>
                        </div>
                        {/* <button
                            onClick={()=> setShowModal(true)}
                            className={faqStyles.getInTouchButton}
                            aria-label="Get in touch with our support team"
                        >
                            Get in touch
                        </button> */}
                    </div>
                </section>
            </main>
            <CustomModal closeOnOutsideClick={false} show={showModal} isClosable={true} onClose={() => setShowModal(false)}>
                <QueryModal onClose={() => setShowModal(false)} />
            </CustomModal>
            <Footer />
        </>
    );
};